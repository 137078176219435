#comments {
    padding: 10px !important;
    width: 100%
}

#comments form {
    display: block;
    margin-top: 0em;
}

#comments input {
    height: 40px;
}

#comments input[type='text'] {
    width: 70%;
    min-width: 190px!important;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
    float: left;
}

#comments input[type='button'] {
    width: 30%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
}

#comments .single-message {
    margin-top: 30px;
}

#comments .image-container {
    padding: 0;
    text-align: right;
}

#comments .single-message img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
}

.content-container {
    padding-left: 0;
}

#comments .content-container h3 {
    text-align: center;
}


#comments .content-container .user-comments:nth-child(2) .comment-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    word-wrap: break-word;
}

#comments .content-container .user-comments:last-child .comment-content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-wrap: break-word;
}

#comments .single-message .author {
    font-weight: bold;
    text-align: center;
}

#comments .single-message .publish-date {
    font-weight: bold;
    text-align: right;
    color: #ccc;
    width: 100%;
    padding-right: 15px;
}

#comments .single-message .comment-content {
    padding: 10px;
    background: #d5f2ef;
    border: 1px solid #e3e3e3;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
    word-wrap: break-word;
}

#comments .single-message .comment-content .comment-actions {
    text-align: right;
}

#comments .comment-actions a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}

.modalConfirms i{
    margin: 15px;
}

.post-button, .post-button:focus{
    width: 30%;
    border: 1px solid #ccc;
    border-left: 0;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
    height: 40px;
}
