/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16.05.2019, 11:18:18
    Author     : Veronika Krasteva <veronikavalerieva@gmail.com>
*/

/*
  ##Device = Most of the Smartphones Mobiles
*/
@media (max-width: 767px){
    .homepage h1, .who-we-are-page .about h2, .heroshot .text-area h1, .asia-forum .asia-forum-social-area h2, .asia-forum .speakers h1,.programe a h1, .programe a h1 span {
    font-size: 30px !important;
    }
    .who-we-are-page .heroshot .text-area h1, .who-we-are-page .heroshot .text-area h4 {
    padding-left: 0;
    }
    .who-we-are-page .heroshot .bounce, .homepage .heroshot .bounce {
        display: none;
    }
    .homepage .heroshot .icon-scroll{
      display: none;
    }
    .footer-new .footer-top ul li {
      padding: 10px 0;
      border-bottom: 1px solid #3e5262;
    }
    .legend{
      display: none;
    }
    .filter-area .date-range{
      margin-bottom: 20px;
    position: static;
    }
    .footer-new .footer-top ul {
      display: block;
    }
    .contacts .heroshot .text-area h1 {
      font-size: 100px!important;
   }
   .Toastify__toast-container--top-center {
    top: 1em;
    left: 0;
    right: 0;
    margin-left: 0px;
    width: 100%!important;
  }
  .icon-login{
    padding: 0px!important;
  }
  .ag-theme-material .ag-paging-panel > span{
    margin-left: 0px;
  }
  .onlyMobile{
    display: block;
  }
  .blue-button.large{
    padding: 10px 40px;
    margin-top: 20px;
  }
  .asia-forum.virtual-forum .programme{
    padding: 50px 0px;
  }
  .speaker-slide {
    max-width: 169px;
  }
  .sponsors-box{
    display: grid;
  }
  .speakers-agenda {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2,1fr);
  }
}
/* Menu */
@media (max-width: 767px){
  .new-menu i.ion-log-in {
  display: table;
  }
  .members-menu .members-menuMobile{
    float: left!important;
    display: grid;
    text-align: left;
  }
  .mobileMenu-icon{
    -webkit-filter: invert(1) brightness(50%) sepia(100%) saturate(10000%);
    filter: invert(1) brightness(50%) sepia(100%) saturate(10000%);
    font-size: 45px;
  }
  .logoText{
    display: none;
  }
  .login-linkNew-icon {
    -webkit-filter: none;
    filter: none;
  }
}
/* Homepage */
@media (max-width: 767px){
  .homepage .heroshot {
    background: #203648;
    display: table;
    width: 100%;
  }
  .homepage .heroshot img{
    display: none;
  }
  .homepage .heroshot .text-area {
    position: relative;
    transform: none;
    width: 100%;
    padding: 50px 2%;
    left: 0;
    }
  .homepage .homepage-members .five-column {
    width: 99% !important;
    margin: 7.5px .5%;
    }
    .homepage .homepage-locations .five-column {
      width: 49% !important;
    }
}

@media (max-width: 767px){
.filter-area .filter-column {
    display: table;
    width: 100%;
    float: left;
    margin: 0 45px 0 0;
    }
  .filter-area .blue-button {
    width: 100%;
  }
  .filter-area .searchRow {
    display: inline;
  }
  .filter-area .searchField{
    margin: 15px;
  }
  .event-calendar .preview-member-area {
    padding: 0;
  }
  .post-profile .post-profile-social-networks .social-shares {
    margin-bottom: 15px;
  }
  .registerContibute-form{
    padding: 0 10px;
  }
  .registerNotification-form{
    padding: 0 20px;
  }
  .topEmptySpace{
    display: none;
  }
  .DialogContent-employeeProfile h1{
    font-size: 36px;
    margin-bottom: 20px;
  }
  .DialogContent-employeeProfile h2{
    font-size: 24px;
    width: 100%;
  }
  .modal-header h2, .modal-title h2{
    font-size: 14px;
  }
  .modal-header button.modal-button-close{
    padding: 0px;
  }
  .ant-calendar-range{
    width: 100%
  }
  .ant-calendar-date-panel{
    display: inline-grid;
  }
  .ant-calendar-range-part{
    width: 100%
  }
  .tabsPage{
      display: grid;
  }
}
/* Menu */
@media (max-width: 1400px){
    .new-menu .main-menu-social-bar a, .new-menu .main-menu-social-bar .login-link{
      font-size: 11px!important;
    }
    .login-linkNew {
    font-size: 10px!important;
  }
}
@media (max-width: 1067px){
    .new-menu {
      position: absolute;
      top: 0;
      background: white!important;
      padding: 0;
      width: 100%
    }
    .new-menu i.ion-log-in {
      display: table
    }
    .new-menu .main-menu-social-bar.ng-hide {
      display: none !important
    }
    .new-menu .main-menu-social-bar {
      background: #203648;
      display: block;
      position: absolute;
      top: 75px;
      padding: 0 15px;
      width: 320px;
      border: 1px solid #999;
      right: 1px
    }
    .new-menu .main-menu-social-bar a {
      float: left;
      margin: 0 10px;
      margin-right: 0 !important;
      color: white!important;
      line-height: 60px;
      height: 60px;
    }
    .new-menu .main-menu-social-bar a.reading-list {
      margin-top: 14px
    }
    .new-menu .main-menu-social-bar .top-menu-social-bar {
      display: none
    }
    .new-menu .logo img {
      margin-left: 0px;
    }
    .new-menu .logo{
      padding-left: 0px;
    }
    .hide-menu .hide-menu-aside {
      width: 100%;
      overflow-y: scroll
    }
    .hide-menu .hide-menu-aside ul {
      height: auto !important;
      padding-top: 0
    }
    .hide-menu .hide-menu-aside ul li {
      height: auto !important;
      padding: 20px 0
    }
    .hide-menu .hide-menu-aside ul li a {
      height: auto !important
    }
    .hide-menu .hide-menu-aside ul.hide-menu-social-bar li {
      padding: 7.5px 0 0 0 !important
    }
    .hide-menu .hide-menu-aside .bottom-area {
      position: relative;
      margin-top: 15px
    }
    .textBottom-contribute{
      position: inherit;
    }

}
/* Who we are */
@media only screen and (max-width: 1076px) {
  .who-we-are-page .heroshot img, .individual-member-page .heroshot img {
    display: none;
    }
  .who-we-are-page .heroshot, .individual-member-page .heroshot {
    display: table;
    padding: 15px 0;
    background-color: #203648;
    }
  .who-we-are-page .heroshot .text-area, .individual-member-page .heroshot .text-area {
    position: relative;
    bottom: 0;
    padding-left: 0;
    position: relative;
    top: 15px;
    padding-left: 10px;
    }
    .who-we-are-page .heroshot .text-area h1, .individual-member-page .heroshot .text-area h1 {
    padding-left: 0;
    }
    .who-we-are-page .heroshot .text-area h2, .individual-member-page .heroshot .text-area h2{
    font-size: 21px;
    }
    .who-we-are-page .heroshot .text-area h4, .individual-member-page .heroshot .text-area h4{
    font-size: 21px;
    padding-left: 0;
    }
   .who-we-are-page .about img {
    padding: 25px 0;
    }
    .who-we-are-page .image-area .image-area-thumb img {
    height: 100% !important;
    }
    .who-we-are-page .image-area .image-area-thumb .image-hover {
    visibility: visible;
    opacity: 1;
    background: rgba(0,0,0,0.5);
    }
    .who-we-are-page .image-area .image-area-thumb .image-hover hr, .who-we-are-page .image-area .image-area-thumb .image-hover p {
    visibility: visible;
    opacity: 1;
    }
    .who-we-are-page .about.background-right, .who-we-are-page .about.background-left {
    padding: 15px 0;
    }
}
@media only screen and (max-width: 1076px) {
  .news-list .heroshot>img,.focus-area-page .heroshot>img,.asia-forum .heroshot>img,.event-calendar .heroshot>img, .contacts .heroshot>img {
    display: none;
}
  .news-list .heroshot .text-area, .focus-area-page .heroshot .text-area, .asia-forum .heroshot .text-area, .event-calendar .heroshot .text-area,.contacts .heroshot .text-area {
    position: relative;
    bottom: 0;
    top: 0;
  }
  .contacts .heroshot .text-area {
    top: 30px;
}
  .news-list .heroshot, .focus-area-page .heroshot, .asia-forum .heroshot, .event-calendar .heroshot, .contacts .heroshot {
    display: table;
    width: 100%;
    padding: 75px 0 50px 0;
    background: #203648;
    }
    .right-header {
      background: #1cbbb4;
      padding: 15px;
      display: table;
      width: 100%;
      height: auto !important;
    }
    .post-profile .author-profile-information {
      bottom: 80px;
      padding: 0 20px 0 20px;
      position: relative;
    }
    .post-profile .author-profile-information p{
      color: white;
    }
    .post-profile .author-profile-information h5{
      color: white;
    }
    .map-height{
        height: 500px;
    }
    .homepage-news .max-width{
    display: inherit;
    }

    .forum-threads .thread-row {
      font-size: 12px;
      height: auto;
      line-height: 24px;
    }
    .forum-threads .threads-head {
      font-size: 12px;
    }
}
@media only screen and (max-width: 1090px) {
  .who-we-are-page .heroshot .text-area{
    bottom: 0;
    }
  .homepage .heroshot .icon-scroll{
      display: none;
    }
    .news-list .heroshot .text-area img, .focus-area-page .heroshot .text-area img, .asia-forum .heroshot .text-area img, .event-calendar .heroshot .text-area img, .contacts .heroshot .text-area img {
      width: 130px;
      }
}
/*
  ##Device = Desktops
*/
@media screen and (min-width: 1360px){
  .homepage .homepage-members .five-column {
    width: 19%;
  }
}
@media (max-width: 1366px){
    .max-width {
      max-width: 100% !important;
    }
}
@media only screen and (min-width: 1800px) {
    .who-we-are-page .image-area-thumb, .who-we-are-page .image-area-blue-box, .who-we-are-page .image-area .image-area-thumb img{
    height: 450px;
    }
}
/* ADMIN */
@media (max-width: 767px){
  .moduleSubMenu .breadcrumb{
    margin-left: 10px;
  }
  .rbc-toolbar {
    display: inline-grid;
  }
  .rbc-toolbar .rbc-toolbar-label{
    font-size: 20px;
    margin: 15px 0px;
  }
  .user-member-list .filter-area .filter-search-box{
    float: none!important;
    margin: auto;
  }
  .frontend-user-panel a.logout{
        margin: 9px 0px;
  }
  .mobileTable td, .mobileTable th{
    padding: 4px;
  }
  .mobileMargin10{
    margin: 10px;
  }
  .customButtons .btn{
    white-space: normal;
  }
  .toolbar{
    display: block!important;
  }
  .select2-position{
    width: 60%;
  }
   .customDialog .MuiDialog-paperWidthMd{
     height: inherit;
   }
}
