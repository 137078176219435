#sidebar ul li a svg {
    padding: 0 5px 0 0;
    background: #606d92;
      /* background: -webkit-linear-gradient(left,#8b73d3,#66b8c9);
    background: -o-linear-gradient(right,#8b73d3,#66b8c9);
    background: -moz-linear-gradient(right,#8b73d3,#66b8c9);
    background: linear-gradient(to right,#8b73d3,#66b8c9);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.search-field {
    position: relative;
    float: right;
    background: white;
    min-width: 240px;
    background: #fff;
    height: 40px;
    margin-top: 7px;
}

#ag-grid-div input[type="text"] {
    height: 40px;
    /* line-height: 40px; */
    /* padding: 0 5px; */
    /* float: left; */
    /* border-radius: 4px; */
    /* color: #444; */
    /* font-weight: 300; */
    /* font-size: 16px; */
    background: transparent;
    /* min-width: 320px; */
    /* padding: 10px 0; */
    /* margin-bottom: 15px; */
    outline: 0;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;
}
.search-field-container {
    position: absolute;
    right: 0;
}
.search-field svg.fa-search {
    position: absolute;
    left: 28px;
    top: 15px;
    color: #a6a6a6;
}

.search-field svg.fa-times {
    cursor: pointer;
    position: absolute;
    right: -13px;
    top: 15px;
    color: #a6a6a6;
    font-size: 11px;
}


.button-primary {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}
.modal-dialog {
    display: inline-table;
    text-align: left;
    vertical-align: middle;
}
.modal-dialog.modal-lg{
    right: 0;
    bottom: 0;
    left: 0;
    top: 30px;
    width: 80%;
    border: 0;
    z-index: 2001;
    position: absolute;
}
ul.modal-row li {
        margin-left: 20px;
}
.modal-header .close{
      margin-top: -10px;
}

.marginBottom {
    margin-bottom: 20px;
}

/* Header*/
.header .top-nav .top-menu .dropdown{
   margin-right: 10px;
}
.header .top-nav .top-menu .dropdown img{
    padding-right: 7px;
  }
.dropdown-toggle::after{
   color: white;
}
.dropdown-notification-menu .dropdown-toggle::after {
    margin-left: 25px;
}
.top-nav .global-search{
  display: none;
}
/* Account profile*/
.profile-desk h1{
    font-family: inherit;
    font-weight: bold;
    padding-bottom: 20px;
}
.accountTitle{
   margin: 0px 0px 0px 10px;
   word-break: break-word;
}
.organizationTitle{
  position: absolute;
  left: 25px;
}
.CardContent:last-child{
   margin-bottom: 0px;
}
/* Account form*/
label.formAddress-label {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #333333;
    font-weight: 400;
}
#reactForm .tmc-reply, div.mce-edit-area {
    min-height: auto!important;
}
/* Duplicates records */
.duplicateRecords-form label{
   text-transform: none;
   font-size: 14px;
   font-family: 'OpenSans',sans-serif;
}

.modal{
      overflow-y: auto;
}
.height48{
  height: 48px;
}
.builder-row-selected{
    background: #cfd8dc;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    margin: 8px;
    padding: 8px;
}

.builder-quest-selected{
    background: #eceff1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    margin: 8px;
    padding: 8px;
}
.panel-body h4 {
    line-height: 1.5;
}
.panel-body h2 {
    font-weight: 600;
  }
.pageAction{
    margin: 20px;
    font-size: 16px;
    padding-left: 20px!important;
}
/* Help section*/
.help-content{
  padding: 10px;
}
