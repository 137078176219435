.example, .example > * {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.example {
    font-size: 14px;
    padding: 0 40px;
    min-height: calc(100vh - 100px);
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    height: calc(100vh - 100px);
    width: 100%;
    margin: auto;
}
.example .rbc-calendar {
    -ms-flex: 1 1;
    flex: 1 1;
    min-height: 580px;
}
.rbc-calendar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.examples {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
}
.rbc-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

@media (max-width: 767px){
    .example .rbc-row-segment .rbc-event-content{
      white-space:normal;
      font-size: 14px
    }
  }
