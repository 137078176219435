/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 30.05.2019, 11:48:22
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
.filter-element-title, .interactive-map .filter-area.visible .filter-popup .filter-element-title {
    color: #b4c3cf;
    font-weight: 600;
    font-family: 'Montserrat',sans-serif;
    border-bottom: 1px solid #3d6281;
    height: 80px;
    line-height: 80px;
    display: block;
    margin-bottom: 0;
}

.dropparrent {
    padding: 0;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #3d6281;
}

.dropparrent a {
    color: #768998;
    display: block;
    transition: .3s;
    font-size: 14px;
}
 .dropparrent a:hover {
    text-decoration: none;
    transition: .3s;
    color: #fff;
    font-size: 14px;
}

.dropparrent a span {
    width: 35px;
    height: 35px;
    display: table;
    float: left;
    background: #5b7e9b;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 12.5px;
    position: relative;
    pointer-events: none;

}

.dropparrent a span i {
    background-size: 25px auto;
    background-position: 5px 5px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    opacity: .3;
    transition: .3s;
}



.dropparrent a .Event i {
    background-image: url('/bundles/contribution/images/icons/Interactive-map-icons/1.png');
}

a:hover .Event i {
     background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/1-white.png);
}
.dropparrent a .Success.story i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/3.png);
}
.dropparrent a .Tool i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/6.png);
}
.dropparrent a .Project i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/2.png);
}
.dropparrent a .Training i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/4.png);
}
.dropparrent a .Gender.Equality i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/gender-equality-black.png);
}
.dropparrent a .Medical.Products i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/medical-black.png);
}
.dropparrent a .Procurement i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/procurement-black.png);
}
.dropparrent a .Transportation i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/transportation-black.png);
}
.dropparrent a .Water i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/water-black.png);
}
.dropparrent a .Energy i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/energy-black.png);
}
.dropparrent a .Human.Labour i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/human-labour-black.png);
}
.dropparrent a .Packaging i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/packaging-black.png);
}
.dropparrent a .Resource.Efficiency i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/resource-efficiency-black.png);
}
.dropparrent a .Waste.Management i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/waste-management-black.png);
}
a:hover .Project i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/2-white.png);
}

.dropparrent a .Publication i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/5.png);
}

a:hover .Publication i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/5-white.png);
}

.dropparrent a .SPHS.Secretariat i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/flag-icon.png);
}

.dropparrent a .Global.Network i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/7.png);
}

a:hover .Global.Network i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/7-white.png);
}

.dropparrent a .Chemicals i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/chemicals-black.png);
}
a.filter-element-title:hover{
  text-decoration: none;
  transition: .3s;
  color: #fff;
}
.dropparrent a:hover i{
    opacity: 1;
}
.dropparrent a:hover .Event {
    background-color: #00adef;
}
.dropparrent a:hover .Water{
    background: #4a275e;
}
.dropparrent a:hover .Project {
    background-color: #b92257;
}
.dropparrent a:hover .Publication {
    background-color: #743f87;
}
.dropparrent a:hover .Success.story {
    background-color: #f5861b;
}
.dropparrent a:hover .Tool {
    background-color: #09b86d;
}
.dropparrent a:hover .Training {
    background-color: #5ea815;
}
.dropparrent a:hover .Chemicals {
    background-color: #4a275e;
}
.dropparrent a:hover .Gender.Equality {
    background-color: #6a094d;
}
.dropparrent a:hover .Medical.Products, .dropparrent a:hover .Procurement{
    background-color: #203648;
}
.dropparrent a:hover .Transportation {
    background-color: #5674b9;
}
.dropparrent a:hover .Water {
    background-color: #1cbbb4;
}
.dropparrent a:hover .Energy {
    background-color: #bad346;
}
.dropparrent a:hover .Human.Labour {
    background-color: #00a651;
}
.dropparrent a:hover .Packaging {
    background-color: #7f620e;
}
.dropparrent a:hover .Resource.Efficiency {
    background-color: #a67c52;
}
.dropparrent a:hover .Waste.Management {
    background-color: #0b5e56;
}
.dropparrent a:hover .SPHS.Secretariat {
    background-color: #09b86d;
}
.dropparrent a:hover .Global.Network {
    background-color: #743f87;
}
.dropparrent a:hover .Success.story i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/3-white.png);
}
.dropparrent a:hover .Tool i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/6-white.png);
}
.dropparrent a:hover .Training i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/4-white.png);
}
.dropparrent a:hover .Gender.Equality i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/gender-equality-white.png);
}
.dropparrent a:hover .Medical.Products i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/medical-shite.png);
}
.dropparrent a:hover .Procurement i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/procurement-white.png);
}
.dropparrent a:hover .Transportation i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/transportation-white.png);
}
.dropparrent a:hover .Water i{
   background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/chemicals-white.png);
}
.dropparrent a:hover .Chemicals i{
  background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/chemicals-white.png);
}
.dropparrent a:hover .Energy i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/energy-white.png);
}
.dropparrent a:hover .Human.Labour i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/human-labour-white.png);
}
.dropparrent a:hover .Packaging i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/packaging-white.png);
}
.dropparrent a:hover .Resource.Efficiency i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/resource-efficiency-white.png);
}
.dropparrent a:hover .Waste.Management i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/waste-white.png);
}
