/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 04.04.2019, 14:54:44
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
html{
    background:#fff
}
body {
    font-family: 'OpenSans',sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family:'OpenSans',sans-serif;
}
h1, .h1 {
    font-size: 36px;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
input[type="text"], input[type="password"], input[type="email"] {
  width: 100%;
  padding: 0 15px;
  height: 48px;
  line-height: 35px;
  border-radius: 0px;
  box-shadow: none;
  border: 0px solid #c7c7c7;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.center-col{
    margin: auto!important;
}
.text-left{
  text-align: left;
}
.pull-left {
    float: left !important;
}
.five-column {
    width: 19%;
}
.new-menu i.ion-log-in {
    display: none;
    float: right;
    height: 60px;
    width: 100px;
    font-size: 30px;
    line-height: 55px;
    cursor: pointer;
}
div:not([ng-bind-html]) ul, div:not([ng-bind-html]) li {
    padding: 0;
    list-style: none;
}
.margin-b-15{
  margin-bottom: 15px;
  display: table;
}
.margin-t-15{
  margin-top: 15px;
}
