/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 04.04.2019, 15:02:18
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
.heroshot{
    position: relative;

}
 .heroshot img {
    width: 100%;
}
img {
    border: 0;
}
.text-gray {
    color: #999;
}

.container-fluid {
    padding: 0;
}
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.page-content {
    max-width: 100%;
}
.col-md-12 {
    width: 100%;
}
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
.page-animation.slideup {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: 100%;
}
.icon-scroll, .icon-scroll:before {
    position: absolute;
    left: 50%;
}
.heroshot .bounce {
    border: 2px solid #fff;
}
.homepage .homepage-members .five-column .member a span.member-desc {
  color: #3e5262;
  font-size: 13px;
  transition: .3s;
}
.homepage .homepage-members .five-column .member:hover span.member-desc{
  color: #ffffff;
}
.homepage .homepage-members .five-column .member a:hover, .homepage .homepage-members .five-column .member a:focus {
    text-decoration: none;
}
/* Menu */

.new-menu {
    transition: .3s;
    background: white;
    height: 100px;
    z-index: 1000;
    box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,.1)
}
.new-menu .logo span{
    margin: 25px;
    font-size: 28px;
    color: #0a0a0a;
}
.new-menu .menu-button{
    z-index:2100;
    position:absolute;
    right: 15px;
}
.new-menu .menu-button button{
    background:transparent;
    border:0;
    display:block;
    font-size:45px;
    height:35px;
    padding:0
}
.new-menu .logo img {
    height: 60px;
    width: auto;
    margin: 15px 0 0 0;
    float: left;
}
.new-menu .main-menu-social-bar a.last {
    margin-right: 85px;
}
.navbar-text{
    color: #fff;
}
.navbar-light .navbar-text a {
    color: #fff;
}
.navbar-light .navbar-brand{
    color: #fff !important;
}
.navbar-light .navbar-text {
    color: #fff !important;
}
.login-linkNew{
    font-size: 12px!important;
    color: #0a0a0a9c!important;
}
.login-linkNew-icon{

      width: 25px;
      filter: invert(1) brightness(50%) sepia(100%) saturate(10000%)
}
.third-menu{
  display: flex;
  outline: none;
  margin: 0 auto;
}
.third-menu a, .third-menu a:focus, .third-menu a:hover{
  text-decoration: none;
}
/* Homepage */
.homepage .heroshot img {
    width: 100%;
}
.homepage .heroshot {
    position: relative;
}
.homepage .heroshot .text-area {
    text-align: center;
    position: absolute;
    z-index: 2000;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    width: 80%;
    left: 10%;
    z-index: 1000;
}
.homepage .heroshot .text-area h1 {
    font-weight: 300;
    font-size: 60px;
}
.homepage .heroshot .text-area h3 {
    font-size: 22px;
    line-height: 28px;
}
.homepage .heroshot .icon-scroll {
    bottom: 50px;
    top: initial;
}
.element-above{
    position: relative;
    z-index: 1;
}
.focus-area-page .sector-area .sector-content .center-align {
    position: relative;
    bottom: 45%;
    top: auto;
  }
  .bounce svg{
    font-size: 56px;
    margin-left: 9px;
  }
/* Who we are */
.who-we-are-page .heroshot .text-area {
    position: absolute;
    padding-left: 50px;
    bottom: 200px;
}
.who-we-are-page .image-area-thumb, .who-we-are-page .image-area-blue-box{
    height: 340px;
}
.individual-member-page .about h1 {
    margin: 50px 0;
    text-align: center;
    font-weight: 300;
    font-size: 52px;
    letter-spacing: 1px;
}
.who-we-are-page .image-area .image-area-thumb img {
    width: 100%;
    height: 340px;
}
/* What you can do */
.map-height{
    height: 900px;
}
/* REGISTRATION FORM */
.contribute-register input{
    font-size: 14px;
}
.contribute-register label span{
 padding: 1px;
}
.blue-button, .footer-new .footer-top .subscribe a, .asia-forum .asia-forum-social-area .blue-button{background-color:#1cbbb4;border-radius:3px;color:#fff;padding:10px 40px;font-size:16px;font-weight: normal;cursor:pointer}
.blue-button:hover{color:#fff; text-decoration: none}

.registerContibute-form .formControl{
    margin: 0px;
}
.topEmptySpace{
    margin-top: 35px;
}
/* REGISTRATION PAGE*/
.image-container img{
    width: 150px;
    height: 150px;
    margin: 25px auto;
}
.contribute-register .regCheckbox label {
        width: 90%;
}
.contribute-register label{
   /* z-index: 1;*/
    font-size: 14px;
    display: table;
}
.contribute-register .register-footer label{
    display: inherit;
    text-transform: none;
    text-align: left;
}
.contribute-register .register-footer{
  background:#d5e3eb;
  display:table;
}
.contribute-register .register-footer button{
  background-color:#1cbbb4;
  border-radius:3px;color:#fff;
  cursor:pointer;
  padding:0;
  width:175px;
  height:35px;
  display:table;
  text-align:center;
  line-height:35px;
  margin:0 auto;
  border:0;
  text-transform:uppercase
}
.Toastify__toast--success {
background: #47a447;
}
/* Login */
.login h1{
    margin:0 0 30px 0;
    font-family: inherit;
}
.login .form-control, .register .form-control{
  border: 1px solid #c7c7c7!important;
}

.transparent-button {
    margin-right: 5px;
    border-radius: 3px;
}
 .transparent-button {
    color: #1cbbb4;
    text-transform: uppercase;
    border: 1px solid #1cbbb4;
    width: 180px;
    float: left;
    height: 40px;
    text-align: center;
    line-height: 40px;
}
.registerContibute .css-11v825g-Input{
    margin: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}
.registerContibute .PrivateSwitchBase-root-158{
    padding: 5px;
}
.individual-contribution-page .individual-contribution-page-content .blue-button{
    height: 50px;
    margin: 30px 0;
}
.individual-contribution-page .individual-contribution-page-content .blue-button img {
    height: 25px;
    width: auto;
    margin: 0 12px 0 0;
}
.individual-contribution-page .individual-contribution-page-content .blue-button span{
    text-transform: uppercase;
    font-weight: bold;
  }
.preview-container .dropzoneLabel{
  font-size: 14px;
    margin-top: 15px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: black;
}
/* Filters*/
.filter-area .filter-search-box  {
    border-color: #c5c4c3;
    line-height: 47px;
    margin: 0;
    width: 100%;
    border-radius: 0 3px 3px 0;
    border-left: 0;
    padding-left: 0;
    color: #c5c4c3;
    border: 1px solid #999;

}
.filter-area .blue-button {
    text-transform: uppercase;
    margin-right: 10px;
    display: table;
    width: 200px;
    float: left;
    height: 40px;
    text-align: center;
    line-height: 40px;
    padding: 4px 0;
    margin-left: 15px;
}
.filter-area .searchRow{
  display: flex;
}
.filter-area .calendar-buttons{
    margin-top: 10px;
    margin-bottom: 15px;
    }

.project-content{
    position: relative;
}
.sector-area{
    z-index: 1;
  }
.event-calendar .preview-member-area .preview-member-project .date{
  margin: 15px 30px;
  border-bottom: 1px solid #999;
  display: table;
  padding-bottom: 10px;
}
.location-label{
  display: block!important;
  text-align: left;
}
.used-filters a.clear-filter{
  margin-left: 30px!important;
  padding-top: 10px;
}

img.No.Poverty{
    background: #E5243B;
}
img.Zero.Hunger{
    background: #DDA63A;
}
img.Good.Health.and.Well-Being{
    background: #4C9F38;
}
img.Quality.Education{
    background: #C5192D;
}
img.Gender.Equality{
    background: #6a094d;
}
img.Clean.Water.Sanitation{
    background: #26BDE2;
}
img.Affordable.and.Clean.Energy{
    background: #FCC30B;
}
img.Decent.Work.Economic.Growth{
    background: #A21942;
}
img.Innovation.Infrastructure{
    background: #FD6925;
}
img.Reduced.Inequalities{
    background: #DD1367;
}
img.Sustainable.Cities.Communities{
    background: #FD9D24;
}
img.Responsible.Consumption.Production{
    background: #BF8B2E;
}
img.Climate.Action{
    background: #3F7E44;
}
img.Life.Below.Water{
    background: #0A97D9;
}
img.Life.Land{
    background: #56C02B;
}
img.Justice.Strong.Institutions{
    background: #00689D;
}
img.Partnerships.Goals{
    background: #19486A;
}
/* K&P LEGEND */
    .legend{
      display: flex;
      margin: 0 10px;
    }
    .legendItem{
      display: grid;
      width: 110px;
      cursor: pointer;
    }
    .legendItem p{
      margin: 0 auto;
    }
    .legendItem span i {
        background-size: 25px auto;
        background-position: 5px 5px;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        height: 35px;
        width: 35px;
        opacity: .3;
        -webkit-transition: .3s;
        transition: .3s;
        opacity: 1;
    }
    .legendItem:hover span{
        opacity: 1;
    }
    .legendItem span {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        position: relative;
        pointer-events: none;
        margin: auto;
        opacity: 0.5;
    }
    .legendItem span:hover {
        opacity: 1;
    }
    .legendItem .legendSelected{
        opacity: 1;
    }

    .legendItem .Event {
        background-color: #00adef;
    }
    .legendItem .Project {
        background-color: #b92257;
    }
    .legendItem .Publication {
    background-color: #743f87;
    }
    .legendItem .Success.story {
        background-color: #f5861b;
    }
    .legendItem .Tool {
        background-color: #09b86d;
    }
    .legendItem .Training {
        background-color: #5ea815;
    }
    .legendItem .Event i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/1-white.png);
    }
    .legendItem .Project i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/2-white.png);
    }
    .legendItem .Publication i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/5-white.png);
    }
    .legendItem .Success.story i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/3-white.png);
    }
    .legendItem .Tool i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/6-white.png);
    }
    .legendItem .Training i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/4-white.png);
    }
/* DATE PICKER*/
    .interactive-map .filter-area .date-range{
        margin-top: 0px!important;
        margin-left: 10px;
      }
    .interactive-map .date-range .DateInput{
        width: 95px;
    }
    .interactive-map .date-range .DateInput_input{
      font-size: 14px;
      text-align: center;
      padding: 0px;
    }
    .interactive-map .date-range .DateInput_input__focused{
      border-bottom: 2px solid #008489!important;
    }
/* News*/
.news-list .post-list-item{
    display: block;
}
.news-list .description{
    overflow: hidden;
    color: #3e5262;
    line-height: 20px;
    font-size: 15px;
    height: 120px;
}
.news-list .follow-bar a{
    display: inline-table;
    margin: 15px 15px 0 0;
    width: 35px;
    height: 35px;
    border: 1px solid #0a99a2;
    text-align: center;
    line-height: 35px;
    color: #0a99a2;
    font-size: 16px;
    border-radius: 100%;
    margin-right: 10px;
}
.news-list .item .item-body{
    padding: 0 25px;
}
.news-list a.post-list-item:hover, .news-list a.post-list-item:focus{
    text-decoration: none;
    outline: none;
}
.news-list .item .item-body .item-created{
    padding-bottom: 5px;
}
.news-list .item .item-body .post-list-creaded{
    font-size: 10px;
    text-align: left;
}
.news-list .item .item-body .category, .homepage-news .post-list-item .category {
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 40px;
  text-transform: uppercase;
  color: white;
  width: 40%;
  margin: 0;
  text-align: center;
  background: transparent;
}
.news-list .item .item-body .category p{
  margin: 0 auto;
}

.news-list .item .item-body .category .In.the.media, .homepage-news .post-list-item .category .In.the.mediа{
      background: #ff5a00;
}
.news-list .item .item-body .category .Stories,.homepage-news .post-list-item .category.Stories{
    background:#b21a7e
}
.news-list .item .item-body .category .SPHS.Publications,.homepage-news .post-list-item .category.SPHS.Publications{
  background:#01c9b8
}
.news-list .item .item-body .category .Voices,.homepage-news .post-list-item .category.Voices{
  background:#123478
}
.news-list .item .item-body .category .NewsFlash,.homepage-news .post-list-item .category.NewsFlash{
  background:#509e3e
}
.news-list .item .item-body .category .Press.Releases,.homepage-news .post-list-item .category.Press.Releases{
  background:#0a99a2;
}
.news-list .item .item-body .category .Newsletters,.homepage-news .post-list-item .category.Newsletters{
  background:#7a49a5;
}

.news-list .buttons{
  display: table;
  margin: 45px auto;
}
.news-list .blue-button {
    background-color: #1cbbb4;
    border-radius: 3px!important;
    color: #fff;
    padding: 10px 40px!important;
    font-size: 16px!important;
    cursor: pointer;
}
.follow-bar img.issuu{
 width: 21px;
}
.jss336 {

    height: auto;

    display: flex;

    padding: 0;

    justify-content: space-between;
  }
.post-profile .post-profile-content .yammer img{
  width: 50%;
}
.more-news-title h3 {
    border-top: 1px solid #147a89;
    padding: 15px 0 30px 15px;
    color: #4a4949;
    margin: 0;
    text-align: left;
}
.news-list .post-list-box {
    border-right: none !important;
}
.btn-secondary{
    background-color: #1cbbb4!important;
    border-radius: 3px!important;
    color: #fff!important;
    cursor: pointer!important;
    padding: 0!important;
    width: 175px!important;
    height: 35px!important;
    display: table!important;
    text-align: center!important;
    line-height: 35px!important;
    margin: 0 auto!important;
    border: 0!important;
    text-transform: uppercase!important;
    font-size: 14px!important;
    font-family: 'OpenSans',sans-serif!important;
}
.projectTitle {
    margin-top: 20px;
}
/*Forums*/
.assessments-page .header p, .asia-forum .about p{
    color: #575757;
    font-size: 21px;
    line-height: 36px;
    overflow: hidden;
}
.asia-forum .text-area h5, .asia-forum .text-area h3, .counting {
    background: #10101066;
    padding: 20px;
}
.counting_delegates {
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 40px;
}
.asia-forum .text-area h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
}
.asia-forum.virtual-forum .text-area h3{
   margin-bottom: 0px;
}
.asia-forum.virtual-forum .about h1 {
    margin: 50px 0px 0;
  }
.asia-forum.virtual-forum.heroshot .asia-forum-logo {
    max-width: 500px;
}
.asia-forum .programme .day .programme-day p {
    line-height: 21px;
}
.asia-forum .programme .day .programme-day ul {
    list-style: disc !important;
}
.asia-forum .programme .day .programme-day ul>li {
    margin-left: 30px;
}
.timezone-picker{
    width: 80%;
    z-index: 999;
    background: white;
    margin-left: 10px;
  }
.timezone-picker input{
    cursor: pointer;
    border-radius: 4px!important;
    border: 1px solid rgba(0, 0, 0, 0.23)!important;
    transition: 0.5s;
    transition-timing-function: linear;
}
.timezone-picker input:hover{
  border-color: rgba(0, 0, 0, 0.87)!important;
}
.timezone-picker input:focus{
  border-color: #3f51b5!important;
}
.timezone-picker ul{
    background: white;
}
.onlyMobile{
  display: none;
}
.forum-reg{
  float: left;
  margin: 70px 0 0 0;
}
.speakers-agenda{
  display: flex;
  margin: 20px 0px;
}
.speaker-slide{
  display: flex;
  max-width: 200px;
}
.speaker-slide a {
    padding: 0px 20px!important;
  }
.speaker-info{
  display: inline-grid;
  line-height: 18px;
  overflow: hidden;
}
.speaker-photo{
  margin-right: 10px;
}
.speaker-name{
  font-weight: 600;
  padding: 2px 0;
  color: #32333C;
  font-size: 14px;
}
.speaker-title{
  color: #32333C;
  font-size: 12px;
}
.blue-button.large{
  padding: 20px 140px;
}
.asia-forum.virtual-forum .sponsors a img{
  filter: none;
}
.asia-forum.virtual-forum .about a.external-link, .asia-forum.virtual-forum .programme a.external-link{
  color: #1890ff!important;
  padding: 0;
  margin: 0;
  display: contents
}
.asia-forum.virtual-forum .programme{
  padding: 50px 15px 100px;
}
/*Contribution*/
element.style {
}
.open-contribution .Event {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/icon-6.png);
}
.open-contribution .type {
    background-repeat: no-repeat;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position: 50%;
    height: 100px;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
}
.open-contribution {
    max-width: 350px;
    min-width: 320px;
    cursor: pointer;
    color: #636e78;
    font-weight: normal;
    font-size: 12px;
}
.individual-contribution-page .individual-contribution-page-content{
    padding-top: 100px;
}
.individual-contribution-page .individual-contribution-page-content .right-header{
    height: 350px;
}
.individual-contribution-page .contribution-area .blue-button{
    margin: 80px 0 30px 0;
}
.individual-contribution-page .contribution-area h3.blue-button {
    font-size: 15px;
    line-height: 50px;
    padding: 0;
    font-family: "Montserrat",sans-serif;
    font-weight: bold;
}

.yj-yam-spittle {
    width: 13px !important;
    height: 11px;
    display: none!important;
    background-image: url('/images/25x25/3fa3f5.png?v1.0g');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle !important;
}
span.yj-share-copy {
    vertical-align: middle !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    display: none;
}
span.yj-share-copy:hover {
    color: #fff !important;
    text-decoration: none !important;
    display: none;
}
.yj-default-share-button {
    /* BUTTON SIZE */
    background-color: transparent !important;
    -moz-border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
    border-radius: 2px !important;
    display: inline-block !important;
    padding: 3px 6px;
    /* font */
    color: #fff !important;
    font-family: Helvetica !important;
    text-decoration: none !important;
    text-align: center !important;
    font-size: 11px !important;
    font-weight: bold !important;
    white-space: nowrap !important;
}

a .yj-default-share-button{
    background-image: url('/images/25x25/3fa3f5.png?v1.0g');
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle !important;
    background-color: transparent !important;
    border-radius: 2px !important;
    height: 49px !important;
    width: 50px !important;
    line-height: 46px !important;
    border-radius: 100% !important;
}
a .yj-default-share-button:hover{
    height: 49px !important;
    margin-bottom: 0!important;
    -webkit-filter: brightness(0) invert(1);
    -moz-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    border: none!important;
    transition: .3s;
}
.post-profile .post-profile-social-networks a.yammer{
    border: transparent;
}
.social-icon{
  color: #999;
  background-color: transparent;
  border: 1px solid #999;
  height: 48px;
  font-size: 23px;
  line-height: 48px;
  border-radius: 100%;
  width: 50px;
  margin-bottom: 15px;
  text-align: center;
  display: table;
  transition: .3s;
  cursor: pointer;
}
.individual-contribution-page .project-social-networks a {
    color: #999;
    background-color: transparent;
    height: 48px;
    font-size: 23px;
    line-height: 44px;
    border-radius: 100%;
    width: 50px;
    text-align: center;
    display: table;
    transition: .3s;
}
.individual-contribution-page .project-social-networks .twitter:hover, .post-profile .post-profile-social-networks .twitter:hover {
    background: #00aced;
    border-color: #00aced;
    color: white;
}
.individual-contribution-page .project-social-networks .facebook:hover, .post-profile .post-profile-social-networks .facebook:hover{
    background: #3b5998;
    border-color: #3b5998;
    color: white;
}
.individual-contribution-page .project-social-networks .email:hover, .post-profile .post-profile-social-networks .email:hover {
    background: #1cbbb4;
    border-color: #1cbbb4;
    color: white;
}
.individual-contribution-page .project-social-networks .linkedin:hover, .post-profile .post-profile-social-networks .linkedin:hover{
    background: #007bb5;
    border-color: #007bb5;
    color: white;
}
.contribute-register  button, .changePass .blue-button  {
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;
}
.individual-contribution-page-content img.contribution-img{
  display: none
}
/* CONTACTS PAGE*/
.Dialog-employeeProfile{
  background: rgba(51,173,82,0.95);
   background: -webkit-linear-gradient(right,rgba(51,173,82,0.95),rgba(6,156,149,0.95));
   background: -o-linear-gradient(left,rgba(51,173,82,0.95),rgba(6,156,149,0.95));
   background: -moz-linear-gradient(left,rgba(51,173,82,0.95),rgba(6,156,149,0.95));
   background: linear-gradient(to left,rgba(51,173,82,0.95),rgba(6,156,149,0.95));
   top: 0;
   left: 0;
   position: fixed;
   z-index: 5000;
   padding: 15px 0;
   opacity: 1;
   visibility: visible;
   transition: all linear .3s;
}

.DialogContent-employeeProfile img {
    float: right;
    border-radius: 100%;
    width: 250px;
    height: 250px;
}
.DialogContent-employeeProfile h1{
    color: #fff;
    font-size: 125px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 50px;
  }
.DialogContent-employeeProfile h2 {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    word-wrap: break-word;
    width: 50%;
}
.DialogContent-employeeProfile p {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    padding: 15px 0;
    height: auto;
}
.DialogContent-employeeProfile a {
    font-size: 21px;
    color: #fff;
    margin: 15px 15px 0 0;
}
.contacts .contacts-content .employee span {
    opacity: 1;
    visibility: visible;
    position: relative;
    width: 300px;
    height: 300px;
}
.contacts .contacts-content .employee .employee-social {
    position: relative;
    bottom: 65px;
    text-align: center;
    width: 100%;
    visibility: hidden;
}
.contacts .contacts-content .employee span>a{
    visibility: hidden;
    cursor: pointer;
}
.contacts .contacts-content .employee span:hover a{
    visibility: visible;
    transition: .3s;
}
.contacts .contacts-content .employee span:hover .employee-social{
    visibility: visible;
    transition: .3s;
}
.contacts .contacts-content .employee span:hover img{
    visibility: visible;
    -webkit-filter: brightness(50%);
    filter: brightness(60%);
    transition: .3s;
}
/* LOGIN MODAL*/
.login-modal .loginModal-footer{
    border-radius: 0 0 3px 3px;
    background: #d5e3eb;
    border-top: 1px solid #909ba4;
    padding: 10px;
    margin: 0px;
    display: block;
}
.login-modal .loginModal-content{
    border-radius: 0 0 3px 3px;
    background: #ebf0f3;
    border-top: 1px solid #909ba4;
    padding: 25px 0 25px 0;
}
.login-modal .loginModal-footer .btn-primary{
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    margin-right: 15px;
    border: 0;
    text-transform: uppercase;

}
  .login-modal .loginModal-content .btn-linkedin{
      color: #fff;
      background-color: #5bc0de;
      border-color: #46b8da;
      margin-top: 20px;
    }
    .login-modal .loginModal-close{
          border-bottom: 1px solid #e5e5e5;
          background: #203648;
    }
    .resetPassword{
      margin: 120px 0;
    }
    .textBottom-contribute{
      bottom: 18%;
      position: absolute;
      padding-left: 20px;
      padding-right: 30px;
    }

/* WELCOME */
    .welcome-page{
        margin-bottom: 100px;
    }
    .frontend-user-panel-menu, .members-menu{
        padding: 0px 25px;
    }
    .changePass{
      padding-top: 50px;
      background: #ebf0f3;
      height: 100%;
    }
    .member-document.page-animation.slide{
      background: #ebf0f3;
    }

/* Questionnaires */

.highlight-green{
   background: green;
   color: white;
}
.highlight-red{
  background: red;
}
/* READING LIST */
    .news-list .contribution-preview a img {
        width: 426px;
        height: 249px;
}
/* FORUM */
    .forum{
      padding: 0 15px;
    }
    .forum-threads {
        font-size: 15px;
    }
    .forum-threads .threads-head {
        font-size: 17px;
        background: #238892;
        color: #ffffff;
        height: 42px;
        line-height: 42px;
    }
    .forum-threads .threads-head:first-child {
        padding-left: 10px;
    }
    .forum-threads .thread-row {
        background: #ffffff;
        color: #2b3238;
        height: 42px;
        line-height: 42px;
        width: 100%
    }
    .forum-threads .thread-row:nth-child(even) {
        background: #f2f2f2;
    }

    .forum-threads .thread-row i.fa-chevron-right {
        margin-right: 15px;
    }

    .btn-undp {
        border-radius: 0 !important;
        margin-right: 10px;
    }
    .btn-default.btn-undp {
        border-color: #527073 !important;
        color: #527073 !important;
    }

    .btn-primary.btn-undp {
        background-color: #527073 !important;
        border-color: #527073 !important;
        text-transform: uppercase;
        margin-bottom: 10px;
        padding: 5px 15px;
        color: white;
        font-size: 14px;
        font-family: 'OpenSans',sans-serif;
}
    .forum-thread{
      background: #F2F2F2;
      padding: 10px;
      margin: 15px 15px 100px 15px!important;
    }
    .forum-thread .author-avatar{
      width: 80px;
      border-radius: 50%;
      border: 1px solid #f2f2f2;
      margin-top: 15px;
    }
    .action-buttons .btn-primary.btn-undp{
      margin-bottom:0px;
    }
    .action-buttons .btn-default{
      margin-right: 10px
    }
    .breadcrumb {
      background: none;
      font-family: 'ProximaNovaA', sans-serif;
    }
    .breadcrumb li span {
      color: #A3A9A8;
    }
    .breadcrumb li a {
      color: #143835;
      text-decoration: none;
    }
    .moduleSubMenu .breadcrumb{
      font-size: 20px;
    }
    .modal-content {
    background: #ebf0f3!important;
    padding: 0px!important;
    }
    .modal-header h2, .modal-title  h2{
      color: #fff;
      font-size: 24px;
    }
    .modal-content .modal-body {
      font-size: 16px;
    }
    .forum-message {
        border-bottom: 10px solid #F2F2F2;
        background: white;
    }
      h5.In.the.mediа{
            background: #ff5a00;
      }
      h5.Stories{
          background:#b21a7e
      }
      h5.SPHS.Publications{
        background:#01c9b8
      }
      h5.Voices{
        background:#123478
      }
      h5.NewsFlash{
        background:#509e3e
      }

      h5.In.the.media{
            background: #ff5a00;
      }

      /*** Panel - new design ***/
      a.profile-nav.alt, a:focus.profile-nav.alt{
         text-decoration:none;
      }
      .wdgt-value p{
          font-size: 12px;
          color: #8b8b8b;
      }

      .wdgt-value h1{
          font-size: 24px;
          font-weight: 600;
          margin: 0 0 15px 0;
          font-family: 'ProximaNovaA', sans-serif;
      }
      .profile-nav .user-heading {
          padding: 30px 30px 10px;
      }
      .profile-nav .user-heading i{
          color:#4eb0e2;
          font-size:50px;
      }
      .profile-nav .panel{
        background: white;
      }
      .panel-body.title-box{
          padding:0 0 25px 0px;
      }
      .panel > .panel-body a:hover{
          text-decoration:none;
      }
 /*** SHIPP ***/

 .tabsPage{
     padding: 50px 0px;
     display: flex;
 }
