body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paid {
    background-color: green;
    border-radius: 4px;
    padding: 3px 15px;
}

.unpaid {
    background-color: red;
    border-radius: 4px;
    padding: 3px 15px;
}

.partialypaid {
    background-color: yellow;
    border-radius: 4px;
    padding: 3px 15px;
}

.table-inbox {
    position: relative;
    overflow: scroll;
    margin: 0;
    background: #f8f9fc;
    border-radius: 4px;
    font-size: 14px;
    min-width: 958px;
    height: 63vh;
    margin-top: 15px;
}
.table-inbox a{
    color: #32323a;
}
.table-inbox a:hover{
    color: #222227;
    text-decoration: underline;
}
.ag-body-viewport {
    overflow-x: scroll;
    overflow-y: scroll !important;
}

.nav.nav-pills li a {
    color: #4eb0e2;
    padding-left: 0;
    padding-top: 5px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'ProximaNovaA', sans-serif;
    float: left;
    position: relative;
    display: block;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin-top: 10px;
}
.nav > h3.module-title{
    font-family: ProximaNovaA, sans-serif;
}
.ag-react-container>span>img {
    width: 55px;
}
#ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown{
    top: -10px;
}
#ag-grid-div > div > div:nth-child(6) > div.col-md-12{
    overflow-y: hidden!important;
}
#ag-grid-div > div > div:nth-child(5) > div.col-md-12{
    overflow-y: hidden!important;
}
#ag-grid-div > div > div:nth-child(6) > div.col-md-12 > div{
    overflow-y: hidden!important;
}

.table-inbox {
    overflow: hidden!important;
}
.dropdown.kit-grid-buttons {
    width: auto;
    margin-bottom: -30px;
}
#ag-grid-div .nav-link, #ag-grid-div .nav-link:link, #ag-grid-div .nav-link:visited, #ag-grid-div .nav-link:active {
    font-size: 14px!important;
}
#ag-grid-div .btn-group {
    width: auto;
    position: absolute;
    top: 1px;
    margin-left: 50px;
}
#ag-grid-div .btn-group button {
    border: 1px solid #44c699;
    background: #44c699;
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    text-transform: uppercase;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    cursor: pointer;
    width: 90px;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle{
    width: 125px;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle > .caret{
    margin-left: 10px;
}

#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle:hover {
    text-decoration: none;
    background: #38b88c !important;
}
li.nav-item> div > ul.dropdown-menu {
    background: #fff;
    border-radius: 3px;
    line-height: 1.46667;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    transition: transform 0.1s ease-out, opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
    font-family: 'ProximaNovaA', sans-serif;
    color: #444;
    font-size: 16px;
    padding: 0;
}
li.nav-item> div > ul.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
    color: #333;
}
.nav-link>span>i {
    margin-right: 6px;
}
.dropdown.kit-grid-buttons button {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 10px;
    left: 0;
}

.dropdown.kit-grid-buttons .dropdown-menus {
    top: 30px;
    left: 10px;
}

.dropdown-menus {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    /* display: none;*/
    float: left;
    min-width: 160px;
    padding: 5px;
    margin: 5px;
    list-style: none;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
}

.deletedButt {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#ag-grid-div input[type="text"] {

    height: 40px;
    /* line-height: 40px; */
    /* padding: 0 5px; */
    /* float: left; */
    /* border-radius: 4px; */
    /* color: #444; */
    /* font-weight: 300; */
    /* font-size: 16px; */
    background: transparent;
    /* min-width: 320px; */
    /* padding: 10px 0; */
    /* margin-bottom: 15px; */
    outline: 0;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

}

.marg {
    margin: 10px;
    cursor: pointer;
}

.marg-bottom {
    margin-bottom: 10px;
}

.ag-theme-balham .ag-header-cell-resize {
    position: absolute;
    right: -4px;
    width: 8px;
    z-index: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.kit-grid-button {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
}

.kit-grid-but {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-color: #b0b5b9;
    border-color: #b0b5b9;
    color: #fff;
}

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.dropdown-toggle:focus {
    outline: 0;
}

.btn:hover, .btn:focus {
    color: #333;
    text-decoration: none;
}

.menu {
    z-index: 100;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}
.modalConfirms i.fa.fa-close {
    margin: 15px;
}
.modalConfirms input[type="text"],
.modalConfirms input[type="password"],
.modalConfirms input[type="email"],
.modalConfirms input[type="number"],
.modalConfirms select.form-control,
.modalConfirms .ant-calendar-picker-input{
    min-width: 230px!important;
}

.date-range{
    margin-top: 9px!important;
    margin-bottom: 10px;
    text-align: center;
}

.date-range input {
    cursor: pointer !important;
}

.date-range div {
    border-radius: 4px;
}

.date-range .ant-calendar-picker{
    width: 250px!important;
    min-width: 0px!important;
}

.date-range .ant-calendar-picker-input{
    width: 250px!important;
    min-width: 0px!important;
    font-size: 14px;
    color: rgb(0, 0, 0, 0.65);
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: normal;
    margin-bottom: 0;
}

.date-range .ant-calendar-range-picker-separator{
    vertical-align: baseline!important;
}

.date-range .ant-calendar-picker-clear,
.date-range .ant-calendar-picker-icon{
    top: 65%;
}

.date {
    margin-top: 12px!important;
    text-align: center;
}

.date .ant-calendar-picker{
    width: 150px!important;
    min-width: 0px!important;
}

.date .ant-calendar-picker-input{
    width: 150px!important;
    min-width: 0px!important;
    font-size: 14px;
    padding-left: 10px!important;
    color: rgb(0, 0, 0, 0.65);
    border: none;
    padding-top: 0;

    padding-left: 21px!important;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: normal;
}

.date .ant-calendar-picker-clear,
.date .ant-calendar-picker-icon{
    top:37%!important;
}

.modalConfirms input[type="text"],
.modalConfirms input[type="password"],
.modalConfirms input[type="email"],
.modalConfirms input[type="number"],
.modalConfirms select.form-control,
.modalConfirms .ant-calendar-picker-input{
    min-width: 230px!important;
}

.backendropss {
    z-index: 100000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
}

.modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 60%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
}
.modalS > div.text-right > div > i{
    color: lightgray;
}

ul li.list {
    margin-left: 15px;
}

#ag-grid-div .dropdown-menu-select {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    /*   display: none;*/
    /* float: left;*/
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
}

/* Navigation */

#ag-grid-div .nav {
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
    padding-left: 15px;
}

#ag-grid-div .nav-items {
    padding: 0;
    list-style: none;
    float: left;
}

#ag-grid-div .nav-item {
    display: inline-block;
    margin-right: -15px;
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 14px;
}

#ag-grid-div .nav-item >a>i {
    font-size: 20px;
    position: absolute;
}
#ag-grid-div .nav-item.dropdown >a >svg{
    font-size: 20px;
    position: absolute;
    color: rgb(165, 163, 163);
    top: -26px
}
#ag-grid-div .nav-item.dropdown.add >a>i {
    top: -2px;
}

#ag-grid-div .nav-item >a>i.ion-plus-round {
    display: none;
}

#ag-grid-div .nav-link,
#ag-grid-div .nav-link:link,
#ag-grid-div .nav-link:visited,
#ag-grid-div .nav-link:active,
#ag-grid-div .submenus-link,
#ag-grid-div .submenus-link:link,
#ag-grid-div .submenus-link:visited,
#ag-grid-div .submenus-link:active {
    display: block;
    position: relative;
    font-size: 16px;
    /* letter-spacing: 1 px;*/
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

#ag-grid-div .nav-link,
#ag-grid-div .nav-link:link,
#ag-grid-div .nav-link:visited,
#ag-grid-div .nav-link:active {
    color: #4eb0e2;
    font-weight: bold;
    /*    text-transform: uppercase;*/
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
}

#ag-grid-div .nav-link::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* height: 3 px;*/
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
}

#ag-grid-div .nav-link:hover::before,
#ag-grid-div .nav-link:hover::before {
    opacity: 1;
    -webkit-transform: translate(0, 5px);
    transform: translate(0, 5px);
}

#ag-grid-div .dropdown {
    position: relative;
}

#ag-grid-div .dropdown .nav-link {
    padding-right: 15px;
    height: 17px;
    line-height: 17px;

}

#ag-grid-div .dropdown .nav-link::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    border: 5px solid transparent;
    border-top-color: #4eb0e2;
}

.submenus {
    position: absolute;
    top: 140%;
    left: 100%;
    z-index: 100;
    max-width: 200px;
    margin-left: -80px;
    background: #fff;
    border-radius: 3px;
    line-height: 1.46667;
    margin-top: -5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    transition: transform 0.1s ease-out, opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
    pointer-events: auto;
    display: table;
}

.border-del {
    border-top: 1px solid #ccc !important;
    margin: 0;
}

.submenus-items {
    list-style: none;
    padding: 0;
    font-family: 'ProximaNovaA', sans-serif;
    color: #444;
    font-size: 12px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

#ag-grid-div .submenus-items .inner-list {
    z-index: 1!important;
    position: absolute;
    left: 100px;
    top: 0px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
}

#ag-grid-div .submenus-items i {
    margin-left: 3px;
    font-size: 12px;
}


.submenus-item {
    display: block;
    text-align: left;

}

.submenus-link,
.submenus-link:link,
.submenus-link:visited,
.submenus-link:active {
    color: #444;
    padding: 4px 20px;
    font-size: 16px;
    font-family: 'ProximaNovaA', sans-serif;
}

.submenus-link:hover {
    text-decoration: underline;
}

.submenus-seperator {
    height: 0;
    margin: 12px 10px;
    border-top: 1px solid #eee;
}

.show-submenus .submenus {
    opacity: 1;
    pointer-events: auto;
}

.submenus-link:hover {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    color: #444;
}

select {
/*    -webkit-appearance: none;
    -moz-appearance: none;*/

}

.selectDots {
    color: #444;
    background: transparent !important;
    border: none !important;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    margin-left: 20px;
}

.option-link {
    color: #444;
    padding: 10px 20px;
    font-size: 12px;
    font-family: 'ProximaNovaA', sans-serif;
    background-color: white !important;
    -moz-appearance: none;
}

ul li ol li {
    list-style: none;
}

li.dropdownCell a {
    margin-left: 10px;
    /* padding: 10 px;*/
}

li.dropdownCell:hover {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
}

.dropdownCellLink {
    margin-left: 10px;
    text-decoration: none;
}

.dropdownCellLink:hover {
    margin-left: 10px;
    text-decoration: none;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
    outline-offset: 0;
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 80px;
    vertical-align: middle;
    margin-right: 15px;
}

input[type="text"] {
    min-width: 120px;
}

#ag-grid-div .col-xs-1, #ag-grid-div .col-sm-1, #ag-grid-div .col-md-1, #ag-grid-div .col-lg-1,
#ag-grid-div .col-xs-2, #ag-grid-div .col-sm-2, #ag-grid-div .col-md-2, #ag-grid-div .col-lg-2,
#ag-grid-div .col-xs-3, #ag-grid-div .col-sm-3, #ag-grid-div .col-md-3, #ag-grid-div .col-lg-3,
#ag-grid-div .col-xs-4, #ag-grid-div .col-sm-4, #ag-grid-div .col-md-4, #ag-grid-div .col-lg-4,
#ag-grid-div .col-xs-5, #ag-grid-div .col-sm-5, #ag-grid-div .col-md-5, #ag-grid-div .col-lg-5,
#ag-grid-div .col-xs-6, #ag-grid-div .col-sm-6, #ag-grid-div .col-md-6, #ag-grid-div .col-lg-6,
#ag-grid-div .col-xs-7, #ag-grid-div .col-sm-7, #ag-grid-div .col-md-7, #ag-grid-div .col-lg-7,
#ag-grid-div .col-xs-8, #ag-grid-div .col-sm-8, #ag-grid-div .col-md-8, #ag-grid-div .col-lg-8,
#ag-grid-div .col-xs-9, #ag-grid-div .col-sm-9, #ag-grid-div .col-md-9, #ag-grid-div .col-lg-9,
#ag-grid-div .col-xs-10, #ag-grid-div .col-sm-10, #ag-grid-div .col-md-10, #ag-grid-div .col-lg-10,
#ag-grid-div .col-xs-11, #ag-grid-div .col-sm-11, #ag-grid-div .col-md-11, #ag-grid-div .col-lg-11,
#ag-grid-div .col-xs-12, #ag-grid-div .col-sm-12, #ag-grid-div .col-md-12, #ag-grid-div .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
}

.search-field-container {
    position: absolute;
    right: 0;
}

.search-field {
    position: relative;
    float: right;
    background: white;
    min-width: 240px;
    background: #fff;
    height: 40px;
    margin-top: 7px;
}

.search-field i.fa.fa-search {
    position: absolute;
    left: 28px;
    top: 15px;
    color: #a6a6a6;
}
.search-field i.fa.fa-times {
    cursor: pointer;
    position: absolute;
    right: -13px;
    top: 15px;
    color: #a6a6a6;
    font-size: 11px;
}

input.search-field-input {
    float: right !important;
    width: 80% !important;
    border-bottom: 1px solid #32323A!important;
    border-radius: 0!important;
    height: 30px!important;
    padding: 0!important;
}
input.search-field-input:active,
input.search-field-input:focus {
    border-bottom: 2px solid rgb(68, 198, 153)!important;
}

.goto-field {
    background: white;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    height: 40px;
    padding: 0;
    width: 40px;
    margin-top: 10px;
    margin-left: 13px;
}

.DateRangePicker {
    position: relative;
    display: inline-block;
}
.DateRangePickerInput__withBorder {
    border: 0!important;
}
.columns-container {
    margin-right: 30px;
    position: relative;
    display: inline-block;
}

.columns-button {
    margin-left: 0;
    margin-top: 10px;
    background: transparent;
    border-radius: 4px;
    border: none;
    height: 40px;
    line-height: 40px;
}

.columns-button-open {
    cursor: pointer;
/*    padding-left: 22px;*/
}
.columns-button-open > a>i{
    color: rgb(166, 166, 166);
}

.columns-button span {
    margin-left: 5px;
}

.columns-button a {
    padding: 0;
    text-align: center;
    font-size: 14px;
}

.columns-button a:hover, .columns-button a:active {
    text-decoration: none;
}

.grid-settings {
    position: relative;
    top: 19px;
    left: 15px;
}
.grid-settings>i{
    color: rgb(166, 166, 166);
}

.check-fa {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
}

.button-primary-new {
    text-transform: uppercase;
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 50px;
    height: 35px;
    /* float: left;*/
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    /* margin: 15 px;*/
    min-width: 125px;
    cursor: pointer;
}
.button-primary-new:hover, .button-primary:hover {
    text-decoration: none;
    background: #38b88c !important;
}
.fontStyle {
    font-family: 'ProximaNovaA', sans-serif;
}

.ag-dnd-ghost {
    display: none !important;
}

.booleanTrue {
    color: green;
}

.booleanFalse {
    color: red;
}

.ag-react-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ag-theme-balham .ag-root {
    border: 0!important;
}
/*Problem with calendar in grids*/
/*.ag-theme-balham .ag-row-selected, .ag-theme-balham .ag-row-hover, .ag-theme-balham .ag-row-focus {
    z-index: 0;
}*/

.grid-overflow-column {
    overflow: visible;
}

.selected-rows-count {
    font-style: italic;
}
.selected-rows-count>sup {
    top: -0.7em;
    margin-left: 5px;
    font-style: normal;
}
.selected-rows-count:hover {
    text-decoration: line-through;
    cursor: pointer;
}
.grid-panel {
    background: white;
    border-radius: 10px;
    width: 99%!important;
    margin: 7px 0 5px 5px;
    padding: 0;
}

.backendropss.transparent-back {
    background-color: transparent;
}
.modalConfirms.scroll.column-selector {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: 8px;
    top: 183px;
    width: 18%;
    max-height: 350px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
}
.modalConfirms.scroll.column-selector > section > div > div > ul {
    margin-bottom: 0px;
}
.modalConfirms.scroll.column-selector>div>div>i {
    display: none;
}

.modalConfirms.scroll.column-selector>.newPanel>.panel-body {
    padding-bottom: 0;
    padding-left: 5px;
}

.modalConfirms.scroll.column-selector>.newPanel>.panel-body>row>ul {
    margin-bottom:0;
}
.column-selector-item,
.column-selector-item>div>ul>li {
    margin-top: -15px;
    min-width: 200px;
}
.checkmark {
    height: 15px;
    width: 15px;
    border-radius: 0;
}
.custom-checkbox {
    padding-left: 25px;
    font-size: 16px;
}
.column-selector-item .custom-checkbox input:checked ~ .checkmark {
    background-color: rgb(68, 198, 153)!important;
    border-radius: 0!important;
}
.custom-checkbox input:checked ~ .checkmark {
    border-radius: 0!important;
}
.custom-checkbox:hover input ~ .checkmark {
    border-radius: 0!important;
}
.custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 4px;
    height: 10px;
}

.bulk-container {
    padding-left: 0!important;
    margin-top: 22px;
    width: 10%;
}
.field {
    height: 100px;
}

.ant-calendar-picker, .ant-calendar-picker-input {
    width: 100%!important;
    line-height: 1.5;
}

@media only screen and (max-height: 1080px) {
    .table-inbox {
        height: 77vh;
    }
    .newButton-mobile{
      position: inherit!important;
    }
}
@media only screen and (max-height: 900px) {
    .table-inbox {
        height: 75vh;
    }
}

@media only screen and (max-height: 864px) {
    .table-inbox {
        height: 64vh;
    }
}

@media only screen and (max-height: 800px) {
    .table-inbox {
        height: 71vh;
    }
}

@media only screen and (max-height: 768px) {
    .table-inbox {
        height: 64vh;
    }
}

@media only screen and (max-height: 720px) {
    .table-inbox {
        height: 57vh;
    }
}

@media only screen and ( max-width: 767px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
        padding-right: 0;
    }
    .form-group {
        width: 100%;
        margin-right: 0!important;
        margin-left: 0!important;
    }
    .ag-theme-balham.table-inbox {
        margin-left: 0;
        height: 77vh;
    }
    #ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown{
        position: fixed;
        left: 165px;
        top: 25px!important;
        z-index: 9999;
    }
    #ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown > a {
        color: white;
    }
    #ag-grid-div > div > div:nth-child(2) > div > nav {
        position: absolute;
        z-index: 99;
        top: -52px;
    }
    #ag-grid-div > div > div.col-md-3.ol-lg-2 > div > nav {
        margin-top: -51px;
        margin-left: 51px;
    }
    .columns-container {
        position: absolute;
        right: 0;
    }
    .search-field {
        min-width: 225px;
    }
    .search-field i.fa.fa-search {
        left: 13px;
    }
    .modalConfirms {
        width: 100%!important;
    }
    .bulk-container {
        width: auto;
    }
    .nav-items {
        padding-right: 5px;
    }
    .nav-item {
        padding-right: 5px;
    }
    #ag-grid-div .nav-item >a>i.ion-plus-round {
        left: 10px;
        display: block;
    }
    .columns-button {
        margin-top: 0;
        line-height: 0;
        margin-left: 72px;
    }
    .search-field-container {
        right: 10px!important;
        top: 0;
        margin-bottom: 10px;
    }
    .modalConfirms.scroll.column-selector {
        width: 50%;
        top: 125px;
    }
    .column-selector-item, .column-selector-item>div>ul>li {
        margin-top: 25px;
    }
    .custom-checkbox > .checkmark {
        margin-top: -6px;
    }
    .modalConfirms.scroll.column-selector>.newPanel>.panel-body {
        padding-top: 0;
    }
    #main-content.merge-left {
        float: none;
        width: 100%;
    }
    .date-range {
        margin-left: 0px!important;
        position: absolute;
        top: 0;
    }
    .columns-button {
        margin-top: 25px;
    }
    #ag-grid-div .nav-item >a>i.ion-plus-round {
        top: 3px;
        left: 10px;
    }
    #ag-grid-div .btn-group {
        position: relative;
    }
    #ag-grid-div .btn-group button.btn.btn-success {
        width: 50px;
    }
    #ag-grid-div .nav-item.dropdown.add {
        position: absolute;
        top: -12px;
    }
    li.nav-item> div > ul.dropdown-menu {
        margin-left: -80px;
    }
      /*  #ag-grid-div .nav-item.dropdown >a >svg{
      display: none;
    }*/

    /*    FORMS*/
    .hide-field {
        display: none;
    }
    #reactForm > div > div > section.panel
    {
        display: block;
        width: 100%
    }
    #reactForm > div > div > section>.panel-body
    {
        display: block;
        width: 100%;
        padding-right: 15px;
    }
    .ant-calendar-picker-input {
        min-width: 200px;
    }
    input[type="number"] {
        min-width: 200px;
    }
    .newMobile{
      z-index: 9999!important;
      position: fixed!important;
      top: 50px!important;
      left: 230px!important;
      color: white!important;
      font-size: 18px!important;
    }
}

@media only screen and (min-width: 1536px) {

    input.search-field-input {
        float: right !important;
        width: 80% !important;
    }

    .columns-button {
        margin-left: 0;
        font-size: 12px;
        margin-top: 10px;
    }
}

.ant-calendar-range-picker-input {
    background-color: transparent;
    border: 0;
    height: 99%;
    outline: 0;
    width: 44%;
    text-align: center;
}

.date-range .ant-calendar-picker-clear, .date-range .ant-calendar-picker-icon {
    top: 65%;
}

.ant-calendar-picker-clear {
    opacity: 0;
    z-index: 1;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
    pointer-events: none;
    cursor: pointer;
}
.ant-calendar-picker-clear, .ant-calendar-picker-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    right: 12px;
    top: 50%;
    margin-top: -7px;
    line-height: 14px;
    font-size: 12px;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#ag-grid-div .nav {
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
    padding-left: 15px;
        display: block;
}

#ag-grid-div .nav-item {
    display: inline-block;
    margin-right: -15px;
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 14px;
}

.nav>li {
    position: relative;
    display: block;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.custom-tooltip {
  position: absolute !important;
  width: 150px;
  height: 70px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.ag-tooltip {
    position: absolute !important;
    pointer-events: none !important;
    z-index: 9009999 !important;
    background: pink !important;
}
/* .ag-tooltip {
        background-color: white !important;
        color: black;
        border-radius:  2px !important;
        padding: 15px !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: #ebebeb !important;
       

        &.ag-tooltip-hiding {
            opacity: 0;
        }
    }*/
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 30.05.2019, 11:48:22
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
.filter-element-title, .interactive-map .filter-area.visible .filter-popup .filter-element-title {
    color: #b4c3cf;
    font-weight: 600;
    font-family: 'Montserrat',sans-serif;
    border-bottom: 1px solid #3d6281;
    height: 80px;
    line-height: 80px;
    display: block;
    margin-bottom: 0;
}

.dropparrent {
    padding: 0;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #3d6281;
}

.dropparrent a {
    color: #768998;
    display: block;
    -webkit-transition: .3s;
    transition: .3s;
    font-size: 14px;
}
 .dropparrent a:hover {
    text-decoration: none;
    -webkit-transition: .3s;
    transition: .3s;
    color: #fff;
    font-size: 14px;
}

.dropparrent a span {
    width: 35px;
    height: 35px;
    display: table;
    float: left;
    background: #5b7e9b;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 12.5px;
    position: relative;
    pointer-events: none;

}

.dropparrent a span i {
    background-size: 25px auto;
    background-position: 5px 5px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    opacity: .3;
    -webkit-transition: .3s;
    transition: .3s;
}



.dropparrent a .Event i {
    background-image: url('/bundles/contribution/images/icons/Interactive-map-icons/1.png');
}

a:hover .Event i {
     background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/1-white.png);
}
.dropparrent a .Success.story i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/3.png);
}
.dropparrent a .Tool i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/6.png);
}
.dropparrent a .Project i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/2.png);
}
.dropparrent a .Training i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/4.png);
}
.dropparrent a .Gender.Equality i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/gender-equality-black.png);
}
.dropparrent a .Medical.Products i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/medical-black.png);
}
.dropparrent a .Procurement i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/procurement-black.png);
}
.dropparrent a .Transportation i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/transportation-black.png);
}
.dropparrent a .Water i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/water-black.png);
}
.dropparrent a .Energy i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/energy-black.png);
}
.dropparrent a .Human.Labour i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/human-labour-black.png);
}
.dropparrent a .Packaging i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/packaging-black.png);
}
.dropparrent a .Resource.Efficiency i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/resource-efficiency-black.png);
}
.dropparrent a .Waste.Management i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/waste-management-black.png);
}
a:hover .Project i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/2-white.png);
}

.dropparrent a .Publication i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/5.png);
}

a:hover .Publication i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/5-white.png);
}

.dropparrent a .SPHS.Secretariat i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/flag-icon.png);
}

.dropparrent a .Global.Network i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/7.png);
}

a:hover .Global.Network i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/7-white.png);
}

.dropparrent a .Chemicals i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/chemicals-black.png);
}
a.filter-element-title:hover{
  text-decoration: none;
  -webkit-transition: .3s;
  transition: .3s;
  color: #fff;
}
.dropparrent a:hover i{
    opacity: 1;
}
.dropparrent a:hover .Event {
    background-color: #00adef;
}
.dropparrent a:hover .Water{
    background: #4a275e;
}
.dropparrent a:hover .Project {
    background-color: #b92257;
}
.dropparrent a:hover .Publication {
    background-color: #743f87;
}
.dropparrent a:hover .Success.story {
    background-color: #f5861b;
}
.dropparrent a:hover .Tool {
    background-color: #09b86d;
}
.dropparrent a:hover .Training {
    background-color: #5ea815;
}
.dropparrent a:hover .Chemicals {
    background-color: #4a275e;
}
.dropparrent a:hover .Gender.Equality {
    background-color: #6a094d;
}
.dropparrent a:hover .Medical.Products, .dropparrent a:hover .Procurement{
    background-color: #203648;
}
.dropparrent a:hover .Transportation {
    background-color: #5674b9;
}
.dropparrent a:hover .Water {
    background-color: #1cbbb4;
}
.dropparrent a:hover .Energy {
    background-color: #bad346;
}
.dropparrent a:hover .Human.Labour {
    background-color: #00a651;
}
.dropparrent a:hover .Packaging {
    background-color: #7f620e;
}
.dropparrent a:hover .Resource.Efficiency {
    background-color: #a67c52;
}
.dropparrent a:hover .Waste.Management {
    background-color: #0b5e56;
}
.dropparrent a:hover .SPHS.Secretariat {
    background-color: #09b86d;
}
.dropparrent a:hover .Global.Network {
    background-color: #743f87;
}
.dropparrent a:hover .Success.story i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/3-white.png);
}
.dropparrent a:hover .Tool i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/6-white.png);
}
.dropparrent a:hover .Training i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/4-white.png);
}
.dropparrent a:hover .Gender.Equality i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/gender-equality-white.png);
}
.dropparrent a:hover .Medical.Products i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/medical-shite.png);
}
.dropparrent a:hover .Procurement i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/procurement-white.png);
}
.dropparrent a:hover .Transportation i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/transportation-white.png);
}
.dropparrent a:hover .Water i{
   background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/chemicals-white.png);
}
.dropparrent a:hover .Chemicals i{
  background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/chemicals-white.png);
}
.dropparrent a:hover .Energy i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/energy-white.png);
}
.dropparrent a:hover .Human.Labour i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/human-labour-white.png);
}
.dropparrent a:hover .Packaging i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/packaging-white.png);
}
.dropparrent a:hover .Resource.Efficiency i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/resource-efficiency-white.png);
}
.dropparrent a:hover .Waste.Management i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/waste-white.png);
}

.settings_message {
   /* position: fixed;
    bottom: -20px;*/
    width: 100%;
  /*  height: 60px;*/
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.settings_message > p {
    background: #44c699;
    width: 50%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  /*  height: 40px;*/
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 10px 10px;
}

.settings_message > p.error {
    background: #e50000;
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25-Jan-2019, 14:28:13
    Author     : neva
*/
.text-grey {
    color:  #cccbcc!important;
}
.move-right-15 {
    right: 15px;
}
.margin-top-20 {
    margin-top: 20px;
}
td input[disabled] {
    background-color: #ededed!important;
}
.panel-heading {
    text-transform: capitalize;
}
.panel-heading span {
    color: rgb(128, 128, 128);
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'ProximaNovaA',sans-serif;
    cursor: pointer;
}
.add-customer-label:hover{
    text-decoration: underline;
    color: #95d472;
}
 .add-customer-label>p {
    display: inline;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: #95d472;
    line-height: 1;
    cursor: pointer;
}
 .add-customer-label>i {
    font-size: 21px;
    float: left;
    color: gray;
    margin: 0 10px 0 0;
}
.tableCollection .add-customer-label>i{
    font-size: 15px;
    float: left;
    color: gray;
    margin: 1px 4px 0 0;
}
.panel-heading .no-label {
    padding-right: 10px;
}
.panel-heading .row.bottom {
    padding: 32px 20px 33px 14px;
    background: white;
    margin: 20px -30px -15px -30px;
    font-size: 24px;
}
.panel-heading .row.bottom:before {
    content: url('/bundles/system/main/images/sicons/arrow.svg');
    position: absolute;
    margin: -44px 0 0 44%;
}
.panel-heading .row.bottom .total-field {
    font-size: 24px;
}
.panel-heading .row.bottom label {
    font-size: 24px;
}
.billing-data-select .css-va7pk8 {
    font-size: 24px;
}

.meta-data .ant-calendar-picker-input {
    min-width: 100px!important;
}

.panel {
    box-shadow: 0 1px 1px #aaa;
}

.panel.total p.total {
    font-size: 24px;
}

.panel.total {
    min-height: 335px;
}

input:disabled {
    background: #f8f9fc;
}
#reactForm table tr:focus {
    outline: none;
}

.slide-modal .modalConfirms {
    overflow: auto;
}
.slide-modal .modalConfirms input[type="text"], .slide-modal .modalConfirms input[type="password"], .slide-modal .modalConfirms input[type="email"], .slide-modal .modalConfirms input[type="number"], .slide-modal .modalConfirms select.form-control {
    border: 1px solid #d6d6d6;
}
.slide-modal .modalConfirms .css-1aya2g8 {
    border-width: 1px;
}
.total-field, .no-label {
    font-size: 16px;
}
.select2-position{
  position: fixed!important;
  width: 45%;
  z-index: 9999;
}
@media only screen and ( max-width: 767px) {
    .panel-heading {
        padding: 10px;
        letter-spacing: -1px;
    }
    .panel-heading .row.bottom {
        margin: 21px -8px -8px -8px;
        padding: 32px 0 33px 14px;
    }
    .panel-heading .no-label {
        padding-right: 0;
    }
    .slide-modal .modalConfirms {
        width: 100% !important;
        overflow: auto;
    }
    .move-right-15 {
    right: 30px;
}
}

.loaderImage {
    margin-top: 20px;
    -webkit-animation: fade 0.2s linear;
            animation: fade 0.2s linear;
}

@-webkit-keyframes fade {
    0% { margin-top: 40px; }
    50% { margin-top: 30px; }
    100% { margin-top: 20px; }
}

@keyframes fade {
    0% { margin-top: 40px; }
    50% { margin-top: 30px; }
    100% { margin-top: 20px; }
}

@-webkit-keyframes gridSlide {
    0% { margin-top: 20px; }
    50% { margin-top: 10px; }
    100% { margin-top: 0px; }
}

@keyframes gridSlide {
    0% { margin-top: 20px; }
    50% { margin-top: 10px; }
    100% { margin-top: 0px; }
}

.gridLoadRow {
    -webkit-animation: gridSlide 0.2s linear;
            animation: gridSlide 0.2s linear;
}

.gridLoadRow .rowCheckbox {
    float: left;
    width: 16px;
    height: 16px;
    margin: 2px 30px 0px 40px;
}

.gridLoadRow .gridRow {
    float: left;
    width: 90%;
    height: 20px;
    border: none;
    border-radius: 0;
    opacity: 0.4;
}

@media only screen and (max-width: 1100px) {
    .gridLoadRow .gridRow {
        width: 80%;
    }
}

@media only screen and (max-width: 700px) {
    .gridLoadRow .gridRow {
        width: 70%;
    }
}


.even {
    background-color: #d2dbe1;
}

.odd {
    background-color: #F2F2F2;
}

.even, .odd {
    border-radius: 5px;
}

.no-radius {
    border-radius: 0;
    border: 0 !important;
    background-color: #F2F2F2;
    height: 15px;
    margin-bottom: 15px;
}

@-webkit-keyframes formSlide {
    0% { margin-top: 50px; }
    25% { margin-top: 45px; }
    50% { margin-top: 40px; }
    50% { margin-top: 35px; }
    100% { margin-top: 30px; }
}

@keyframes formSlide {
    0% { margin-top: 50px; }
    25% { margin-top: 45px; }
    50% { margin-top: 40px; }
    50% { margin-top: 35px; }
    100% { margin-top: 30px; }
}
.formLoadBlock {
    float: left;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    -webkit-animation: formSlide 0.2s linear;
            animation: formSlide 0.2s linear;
}

.formLoadBlock .fieldHolder {
    width: 100%;
    height: 100%;
    background-color: #f8f9fc;
    border-radius: 5px;
}
#sidebar ul li a svg {
    padding: 0 5px 0 0;
    background: #606d92;
      /* background: -webkit-linear-gradient(left,#8b73d3,#66b8c9);
    background: -o-linear-gradient(right,#8b73d3,#66b8c9);
    background: -moz-linear-gradient(right,#8b73d3,#66b8c9);
    background: linear-gradient(to right,#8b73d3,#66b8c9);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.search-field {
    position: relative;
    float: right;
    background: white;
    min-width: 240px;
    background: #fff;
    height: 40px;
    margin-top: 7px;
}

#ag-grid-div input[type="text"] {
    height: 40px;
    /* line-height: 40px; */
    /* padding: 0 5px; */
    /* float: left; */
    /* border-radius: 4px; */
    /* color: #444; */
    /* font-weight: 300; */
    /* font-size: 16px; */
    background: transparent;
    /* min-width: 320px; */
    /* padding: 10px 0; */
    /* margin-bottom: 15px; */
    outline: 0;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;
}
.search-field-container {
    position: absolute;
    right: 0;
}
.search-field svg.fa-search {
    position: absolute;
    left: 28px;
    top: 15px;
    color: #a6a6a6;
}

.search-field svg.fa-times {
    cursor: pointer;
    position: absolute;
    right: -13px;
    top: 15px;
    color: #a6a6a6;
    font-size: 11px;
}


.button-primary {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}
.modal-dialog {
    display: inline-table;
    text-align: left;
    vertical-align: middle;
}
.modal-dialog.modal-lg{
    right: 0;
    bottom: 0;
    left: 0;
    top: 30px;
    width: 80%;
    border: 0;
    z-index: 2001;
    position: absolute;
}
ul.modal-row li {
        margin-left: 20px;
}
.modal-header .close{
      margin-top: -10px;
}

.marginBottom {
    margin-bottom: 20px;
}

/* Header*/
.header .top-nav .top-menu .dropdown{
   margin-right: 10px;
}
.header .top-nav .top-menu .dropdown img{
    padding-right: 7px;
  }
.dropdown-toggle::after{
   color: white;
}
.dropdown-notification-menu .dropdown-toggle::after {
    margin-left: 25px;
}
.top-nav .global-search{
  display: none;
}
/* Account profile*/
.profile-desk h1{
    font-family: inherit;
    font-weight: bold;
    padding-bottom: 20px;
}
.accountTitle{
   margin: 0px 0px 0px 10px;
   word-break: break-word;
}
.organizationTitle{
  position: absolute;
  left: 25px;
}
.CardContent:last-child{
   margin-bottom: 0px;
}
/* Account form*/
label.formAddress-label {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #333333;
    font-weight: 400;
}
#reactForm .tmc-reply, div.mce-edit-area {
    min-height: auto!important;
}
/* Duplicates records */
.duplicateRecords-form label{
   text-transform: none;
   font-size: 14px;
   font-family: 'OpenSans',sans-serif;
}

.modal{
      overflow-y: auto;
}
.height48{
  height: 48px;
}
.builder-row-selected{
    background: #cfd8dc;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    margin: 8px;
    padding: 8px;
}

.builder-quest-selected{
    background: #eceff1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    margin: 8px;
    padding: 8px;
}
.panel-body h4 {
    line-height: 1.5;
}
.panel-body h2 {
    font-weight: 600;
  }
.pageAction{
    margin: 20px;
    font-size: 16px;
    padding-left: 20px!important;
}
/* Help section*/
.help-content{
  padding: 10px;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2019, 10:11:58
    Author     : neva
*/

input.custom-text {
    background: white;
    height: 100px;
    line-height: 25px;
    margin-top: 10px;
    box-shadow: 0 1px 1px #aaa;
    margin: 30px 0 0 0;
    border-color: #d3d3d3;
}

button[disabled].button-issue {
    background-color: rgba(0,255,0,0.3)!important;
    border: 1px solid rgba(0,255,0,0.3);
}
.form-stage{
    min-width: unset;
    width: 95%;
    height: 40px;
    color: black;
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    border: 0;
}

.contact-icon, .address-icon {
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
  color: rgb(165, 163, 163);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.contact-icon:hover, .address-icon:hover {
  color: red;
}

.add-translations {
    position: absolute;
    left: 48%;
    top: 140px;
}

 i.fa.fa-language {
    cursor: pointer;
}

.form-input-field input[type="text"],
.form-input-field input[type="password"],
.form-input-field input[type="email"],
.form-input-field input[type="number"] {
    border: 1px solid #d6d6d6 ;
    height: 40px !important;
    padding: 0 5px !important;
    float: left !important;
    color:  #444 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    border-radius: 3px !important;
    background: transparent !important;
    min-width: 320px !important;
    padding: 10px 0 !important;
    margin-bottom: 15px !important;
    letter-spacing: 0.5px !important;
    width: 100%;
}



.switchComponent {
    width: 85px;
    height: 30px;
    background: #fff;
    margin-left: 0px;
    margin-bottom: 5px;
    border-radius: 50px;
    position: relative;
    border: 1px solid #d6d6d6;
    z-index: 0;
}

.switchComponent input[type=checkbox] {
    visibility: hidden;
}


.switchComponent:after {
    font: 14px/32px Arial,sans-serif;
    position: absolute;
    right: 16px;
    z-index: -1;
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255,255,255,.15);
    text-transform: uppercase;
    content: '\2715';
}

.switchComponent:before {
    font: 14px/32px Arial,sans-serif;
    color: #44c699;
    position: absolute;
    left: 16px;
    z-index: -1;
    font-weight: bold;
    text-transform: uppercase;
    content: '\2713';
}

.switchComponent label {
    display: block;
    width: 37px;
    height: 24px;
    border-radius: 50px;

    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 3px;
    z-index: 0;
    background: #dbdadb;
}

.switchComponent input[type=checkbox]:checked + label {
    left: 43px;
    background: #44c699;
}

.button-outline:hover {
    text-decoration: none;
    border-bottom-width: 2px;
}

.grey {
    background-color: #fff;
    color: #3c3c3c;
    border: solid 1px #c4c4c4;
}
.button-outline {
    border: 1px solid;
    border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 100px;
    cursor: pointer;
    background: transparent;
}

.button-submit {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}

.button-submit.disabled {
    background-color: #ccc !important;
    border-color: #ccc !important;
    cursor: not-allowed;
}

#comments {
    padding: 10px !important;
    width: 100%
}

#comments form {
    display: block;
    margin-top: 0em;
}

#comments input {
    height: 40px;
}

#comments input[type='text'] {
    width: 70%;
    min-width: 190px!important;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
    float: left;
}

#comments input[type='button'] {
    width: 30%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
}

#comments .single-message {
    margin-top: 30px;
}

#comments .image-container {
    padding: 0;
    text-align: right;
}

#comments .single-message img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
}

.content-container {
    padding-left: 0;
}

#comments .content-container h3 {
    text-align: center;
}


#comments .content-container .user-comments:nth-child(2) .comment-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    word-wrap: break-word;
}

#comments .content-container .user-comments:last-child .comment-content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-wrap: break-word;
}

#comments .single-message .author {
    font-weight: bold;
    text-align: center;
}

#comments .single-message .publish-date {
    font-weight: bold;
    text-align: right;
    color: #ccc;
    width: 100%;
    padding-right: 15px;
}

#comments .single-message .comment-content {
    padding: 10px;
    background: #d5f2ef;
    border: 1px solid #e3e3e3;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
    word-wrap: break-word;
}

#comments .single-message .comment-content .comment-actions {
    text-align: right;
}

#comments .comment-actions a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}

.modalConfirms i{
    margin: 15px;
}

.post-button, .post-button:focus{
    width: 30%;
    border: 1px solid #ccc;
    border-left: 0;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
    height: 40px;
}

.example, .example > * {
    display: flex;
    flex-direction: column;
}
.example {
    font-size: 14px;
    padding: 0 40px;
    min-height: calc(100vh - 100px);
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    height: calc(100vh - 100px);
    width: 100%;
    margin: auto;
}
.example .rbc-calendar {
    flex: 1 1;
    min-height: 580px;
}
.rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.examples {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
}
.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

@media (max-width: 767px){
    .example .rbc-row-segment .rbc-event-content{
      white-space:normal;
      font-size: 14px
    }
  }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 04.04.2019, 14:54:44
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
html{
    background:#fff
}
body {
    font-family: 'OpenSans',sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family:'OpenSans',sans-serif;
}
h1, .h1 {
    font-size: 36px;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
input[type="text"], input[type="password"], input[type="email"] {
  width: 100%;
  padding: 0 15px;
  height: 48px;
  line-height: 35px;
  border-radius: 0px;
  box-shadow: none;
  border: 0px solid #c7c7c7;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.center-col{
    margin: auto!important;
}
.text-left{
  text-align: left;
}
.pull-left {
    float: left !important;
}
.five-column {
    width: 19%;
}
.new-menu i.ion-log-in {
    display: none;
    float: right;
    height: 60px;
    width: 100px;
    font-size: 30px;
    line-height: 55px;
    cursor: pointer;
}
div:not([ng-bind-html]) ul, div:not([ng-bind-html]) li {
    padding: 0;
    list-style: none;
}
.margin-b-15{
  margin-bottom: 15px;
  display: table;
}
.margin-t-15{
  margin-top: 15px;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 04.04.2019, 15:02:18
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
.heroshot{
    position: relative;

}
 .heroshot img {
    width: 100%;
}
img {
    border: 0;
}
.text-gray {
    color: #999;
}

.container-fluid {
    padding: 0;
}
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.page-content {
    max-width: 100%;
}
.col-md-12 {
    width: 100%;
}
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
.page-animation.slideup {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: 100%;
}
.icon-scroll, .icon-scroll:before {
    position: absolute;
    left: 50%;
}
.heroshot .bounce {
    border: 2px solid #fff;
}
.homepage .homepage-members .five-column .member a span.member-desc {
  color: #3e5262;
  font-size: 13px;
  -webkit-transition: .3s;
  transition: .3s;
}
.homepage .homepage-members .five-column .member:hover span.member-desc{
  color: #ffffff;
}
.homepage .homepage-members .five-column .member a:hover, .homepage .homepage-members .five-column .member a:focus {
    text-decoration: none;
}
/* Menu */

.new-menu {
    -webkit-transition: .3s;
    transition: .3s;
    background: white;
    height: 100px;
    z-index: 1000;
    box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,.1)
}
.new-menu .logo span{
    margin: 25px;
    font-size: 28px;
    color: #0a0a0a;
}
.new-menu .menu-button{
    z-index:2100;
    position:absolute;
    right: 15px;
}
.new-menu .menu-button button{
    background:transparent;
    border:0;
    display:block;
    font-size:45px;
    height:35px;
    padding:0
}
.new-menu .logo img {
    height: 60px;
    width: auto;
    margin: 15px 0 0 0;
    float: left;
}
.new-menu .main-menu-social-bar a.last {
    margin-right: 85px;
}
.navbar-text{
    color: #fff;
}
.navbar-light .navbar-text a {
    color: #fff;
}
.navbar-light .navbar-brand{
    color: #fff !important;
}
.navbar-light .navbar-text {
    color: #fff !important;
}
.login-linkNew{
    font-size: 12px!important;
    color: #0a0a0a9c!important;
}
.login-linkNew-icon{

      width: 25px;
      -webkit-filter: invert(1) brightness(50%) sepia(100%) saturate(10000%);
              filter: invert(1) brightness(50%) sepia(100%) saturate(10000%)
}
.third-menu{
  display: flex;
  outline: none;
  margin: 0 auto;
}
.third-menu a, .third-menu a:focus, .third-menu a:hover{
  text-decoration: none;
}
/* Homepage */
.homepage .heroshot img {
    width: 100%;
}
.homepage .heroshot {
    position: relative;
}
.homepage .heroshot .text-area {
    text-align: center;
    position: absolute;
    z-index: 2000;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    width: 80%;
    left: 10%;
    z-index: 1000;
}
.homepage .heroshot .text-area h1 {
    font-weight: 300;
    font-size: 60px;
}
.homepage .heroshot .text-area h3 {
    font-size: 22px;
    line-height: 28px;
}
.homepage .heroshot .icon-scroll {
    bottom: 50px;
    top: auto;
    top: initial;
}
.element-above{
    position: relative;
    z-index: 1;
}
.focus-area-page .sector-area .sector-content .center-align {
    position: relative;
    bottom: 45%;
    top: auto;
  }
  .bounce svg{
    font-size: 56px;
    margin-left: 9px;
  }
/* Who we are */
.who-we-are-page .heroshot .text-area {
    position: absolute;
    padding-left: 50px;
    bottom: 200px;
}
.who-we-are-page .image-area-thumb, .who-we-are-page .image-area-blue-box{
    height: 340px;
}
.individual-member-page .about h1 {
    margin: 50px 0;
    text-align: center;
    font-weight: 300;
    font-size: 52px;
    letter-spacing: 1px;
}
.who-we-are-page .image-area .image-area-thumb img {
    width: 100%;
    height: 340px;
}
/* What you can do */
.map-height{
    height: 900px;
}
/* REGISTRATION FORM */
.contribute-register input{
    font-size: 14px;
}
.contribute-register label span{
 padding: 1px;
}
.blue-button, .footer-new .footer-top .subscribe a, .asia-forum .asia-forum-social-area .blue-button{background-color:#1cbbb4;border-radius:3px;color:#fff;padding:10px 40px;font-size:16px;font-weight: normal;cursor:pointer}
.blue-button:hover{color:#fff; text-decoration: none}

.registerContibute-form .formControl{
    margin: 0px;
}
.topEmptySpace{
    margin-top: 35px;
}
/* REGISTRATION PAGE*/
.image-container img{
    width: 150px;
    height: 150px;
    margin: 25px auto;
}
.contribute-register .regCheckbox label {
        width: 90%;
}
.contribute-register label{
   /* z-index: 1;*/
    font-size: 14px;
    display: table;
}
.contribute-register .register-footer label{
    display: inherit;
    text-transform: none;
    text-align: left;
}
.contribute-register .register-footer{
  background:#d5e3eb;
  display:table;
}
.contribute-register .register-footer button{
  background-color:#1cbbb4;
  border-radius:3px;color:#fff;
  cursor:pointer;
  padding:0;
  width:175px;
  height:35px;
  display:table;
  text-align:center;
  line-height:35px;
  margin:0 auto;
  border:0;
  text-transform:uppercase
}
.Toastify__toast--success {
background: #47a447;
}
/* Login */
.login h1{
    margin:0 0 30px 0;
    font-family: inherit;
}
.login .form-control, .register .form-control{
  border: 1px solid #c7c7c7!important;
}

.transparent-button {
    margin-right: 5px;
    border-radius: 3px;
}
 .transparent-button {
    color: #1cbbb4;
    text-transform: uppercase;
    border: 1px solid #1cbbb4;
    width: 180px;
    float: left;
    height: 40px;
    text-align: center;
    line-height: 40px;
}
.registerContibute .css-11v825g-Input{
    margin: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}
.registerContibute .PrivateSwitchBase-root-158{
    padding: 5px;
}
.individual-contribution-page .individual-contribution-page-content .blue-button{
    height: 50px;
    margin: 30px 0;
}
.individual-contribution-page .individual-contribution-page-content .blue-button img {
    height: 25px;
    width: auto;
    margin: 0 12px 0 0;
}
.individual-contribution-page .individual-contribution-page-content .blue-button span{
    text-transform: uppercase;
    font-weight: bold;
  }
.preview-container .dropzoneLabel{
  font-size: 14px;
    margin-top: 15px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: black;
}
/* Filters*/
.filter-area .filter-search-box  {
    border-color: #c5c4c3;
    line-height: 47px;
    margin: 0;
    width: 100%;
    border-radius: 0 3px 3px 0;
    border-left: 0;
    padding-left: 0;
    color: #c5c4c3;
    border: 1px solid #999;

}
.filter-area .blue-button {
    text-transform: uppercase;
    margin-right: 10px;
    display: table;
    width: 200px;
    float: left;
    height: 40px;
    text-align: center;
    line-height: 40px;
    padding: 4px 0;
    margin-left: 15px;
}
.filter-area .searchRow{
  display: flex;
}
.filter-area .calendar-buttons{
    margin-top: 10px;
    margin-bottom: 15px;
    }

.project-content{
    position: relative;
}
.sector-area{
    z-index: 1;
  }
.event-calendar .preview-member-area .preview-member-project .date{
  margin: 15px 30px;
  border-bottom: 1px solid #999;
  display: table;
  padding-bottom: 10px;
}
.location-label{
  display: block!important;
  text-align: left;
}
.used-filters a.clear-filter{
  margin-left: 30px!important;
  padding-top: 10px;
}

img.No.Poverty{
    background: #E5243B;
}
img.Zero.Hunger{
    background: #DDA63A;
}
img.Good.Health.and.Well-Being{
    background: #4C9F38;
}
img.Quality.Education{
    background: #C5192D;
}
img.Gender.Equality{
    background: #6a094d;
}
img.Clean.Water.Sanitation{
    background: #26BDE2;
}
img.Affordable.and.Clean.Energy{
    background: #FCC30B;
}
img.Decent.Work.Economic.Growth{
    background: #A21942;
}
img.Innovation.Infrastructure{
    background: #FD6925;
}
img.Reduced.Inequalities{
    background: #DD1367;
}
img.Sustainable.Cities.Communities{
    background: #FD9D24;
}
img.Responsible.Consumption.Production{
    background: #BF8B2E;
}
img.Climate.Action{
    background: #3F7E44;
}
img.Life.Below.Water{
    background: #0A97D9;
}
img.Life.Land{
    background: #56C02B;
}
img.Justice.Strong.Institutions{
    background: #00689D;
}
img.Partnerships.Goals{
    background: #19486A;
}
/* K&P LEGEND */
    .legend{
      display: flex;
      margin: 0 10px;
    }
    .legendItem{
      display: grid;
      width: 110px;
      cursor: pointer;
    }
    .legendItem p{
      margin: 0 auto;
    }
    .legendItem span i {
        background-size: 25px auto;
        background-position: 5px 5px;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        height: 35px;
        width: 35px;
        opacity: .3;
        -webkit-transition: .3s;
        transition: .3s;
        opacity: 1;
    }
    .legendItem:hover span{
        opacity: 1;
    }
    .legendItem span {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        position: relative;
        pointer-events: none;
        margin: auto;
        opacity: 0.5;
    }
    .legendItem span:hover {
        opacity: 1;
    }
    .legendItem .legendSelected{
        opacity: 1;
    }

    .legendItem .Event {
        background-color: #00adef;
    }
    .legendItem .Project {
        background-color: #b92257;
    }
    .legendItem .Publication {
    background-color: #743f87;
    }
    .legendItem .Success.story {
        background-color: #f5861b;
    }
    .legendItem .Tool {
        background-color: #09b86d;
    }
    .legendItem .Training {
        background-color: #5ea815;
    }
    .legendItem .Event i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/1-white.png);
    }
    .legendItem .Project i {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/2-white.png);
    }
    .legendItem .Publication i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/5-white.png);
    }
    .legendItem .Success.story i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/3-white.png);
    }
    .legendItem .Tool i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/6-white.png);
    }
    .legendItem .Training i {
        background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/4-white.png);
    }
/* DATE PICKER*/
    .interactive-map .filter-area .date-range{
        margin-top: 0px!important;
        margin-left: 10px;
      }
    .interactive-map .date-range .DateInput{
        width: 95px;
    }
    .interactive-map .date-range .DateInput_input{
      font-size: 14px;
      text-align: center;
      padding: 0px;
    }
    .interactive-map .date-range .DateInput_input__focused{
      border-bottom: 2px solid #008489!important;
    }
/* News*/
.news-list .post-list-item{
    display: block;
}
.news-list .description{
    overflow: hidden;
    color: #3e5262;
    line-height: 20px;
    font-size: 15px;
    height: 120px;
}
.news-list .follow-bar a{
    display: inline-table;
    margin: 15px 15px 0 0;
    width: 35px;
    height: 35px;
    border: 1px solid #0a99a2;
    text-align: center;
    line-height: 35px;
    color: #0a99a2;
    font-size: 16px;
    border-radius: 100%;
    margin-right: 10px;
}
.news-list .item .item-body{
    padding: 0 25px;
}
.news-list a.post-list-item:hover, .news-list a.post-list-item:focus{
    text-decoration: none;
    outline: none;
}
.news-list .item .item-body .item-created{
    padding-bottom: 5px;
}
.news-list .item .item-body .post-list-creaded{
    font-size: 10px;
    text-align: left;
}
.news-list .item .item-body .category, .homepage-news .post-list-item .category {
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 40px;
  text-transform: uppercase;
  color: white;
  width: 40%;
  margin: 0;
  text-align: center;
  background: transparent;
}
.news-list .item .item-body .category p{
  margin: 0 auto;
}

.news-list .item .item-body .category .In.the.media, .homepage-news .post-list-item .category .In.the.mediа{
      background: #ff5a00;
}
.news-list .item .item-body .category .Stories,.homepage-news .post-list-item .category.Stories{
    background:#b21a7e
}
.news-list .item .item-body .category .SPHS.Publications,.homepage-news .post-list-item .category.SPHS.Publications{
  background:#01c9b8
}
.news-list .item .item-body .category .Voices,.homepage-news .post-list-item .category.Voices{
  background:#123478
}
.news-list .item .item-body .category .NewsFlash,.homepage-news .post-list-item .category.NewsFlash{
  background:#509e3e
}
.news-list .item .item-body .category .Press.Releases,.homepage-news .post-list-item .category.Press.Releases{
  background:#0a99a2;
}
.news-list .item .item-body .category .Newsletters,.homepage-news .post-list-item .category.Newsletters{
  background:#7a49a5;
}

.news-list .buttons{
  display: table;
  margin: 45px auto;
}
.news-list .blue-button {
    background-color: #1cbbb4;
    border-radius: 3px!important;
    color: #fff;
    padding: 10px 40px!important;
    font-size: 16px!important;
    cursor: pointer;
}
.follow-bar img.issuu{
 width: 21px;
}
.jss336 {

    height: auto;

    display: flex;

    padding: 0;

    justify-content: space-between;
  }
.post-profile .post-profile-content .yammer img{
  width: 50%;
}
.more-news-title h3 {
    border-top: 1px solid #147a89;
    padding: 15px 0 30px 15px;
    color: #4a4949;
    margin: 0;
    text-align: left;
}
.news-list .post-list-box {
    border-right: none !important;
}
.btn-secondary{
    background-color: #1cbbb4!important;
    border-radius: 3px!important;
    color: #fff!important;
    cursor: pointer!important;
    padding: 0!important;
    width: 175px!important;
    height: 35px!important;
    display: table!important;
    text-align: center!important;
    line-height: 35px!important;
    margin: 0 auto!important;
    border: 0!important;
    text-transform: uppercase!important;
    font-size: 14px!important;
    font-family: 'OpenSans',sans-serif!important;
}
.projectTitle {
    margin-top: 20px;
}
/*Forums*/
.assessments-page .header p, .asia-forum .about p{
    color: #575757;
    font-size: 21px;
    line-height: 36px;
    overflow: hidden;
}
.asia-forum .text-area h5, .asia-forum .text-area h3, .counting {
    background: #10101066;
    padding: 20px;
}
.counting_delegates {
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 40px;
}
.asia-forum .text-area h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
}
.asia-forum.virtual-forum .text-area h3{
   margin-bottom: 0px;
}
.asia-forum.virtual-forum .about h1 {
    margin: 50px 0px 0;
  }
.asia-forum.virtual-forum.heroshot .asia-forum-logo {
    max-width: 500px;
}
.asia-forum .programme .day .programme-day p {
    line-height: 21px;
}
.asia-forum .programme .day .programme-day ul {
    list-style: disc !important;
}
.asia-forum .programme .day .programme-day ul>li {
    margin-left: 30px;
}
.timezone-picker{
    width: 80%;
    z-index: 999;
    background: white;
    margin-left: 10px;
  }
.timezone-picker input{
    cursor: pointer;
    border-radius: 4px!important;
    border: 1px solid rgba(0, 0, 0, 0.23)!important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
}
.timezone-picker input:hover{
  border-color: rgba(0, 0, 0, 0.87)!important;
}
.timezone-picker input:focus{
  border-color: #3f51b5!important;
}
.timezone-picker ul{
    background: white;
}
.onlyMobile{
  display: none;
}
.forum-reg{
  float: left;
  margin: 70px 0 0 0;
}
.speakers-agenda{
  display: flex;
  margin: 20px 0px;
}
.speaker-slide{
  display: flex;
  max-width: 200px;
}
.speaker-slide a {
    padding: 0px 20px!important;
  }
.speaker-info{
  display: inline-grid;
  line-height: 18px;
  overflow: hidden;
}
.speaker-photo{
  margin-right: 10px;
}
.speaker-name{
  font-weight: 600;
  padding: 2px 0;
  color: #32333C;
  font-size: 14px;
}
.speaker-title{
  color: #32333C;
  font-size: 12px;
}
.blue-button.large{
  padding: 20px 140px;
}
.asia-forum.virtual-forum .sponsors a img{
  -webkit-filter: none;
          filter: none;
}
.asia-forum.virtual-forum .about a.external-link, .asia-forum.virtual-forum .programme a.external-link{
  color: #1890ff!important;
  padding: 0;
  margin: 0;
  display: contents
}
.asia-forum.virtual-forum .programme{
  padding: 50px 15px 100px;
}
/*Contribution*/
element.style {
}
.open-contribution .Event {
    background-image: url(/bundles/contribution/images/icons/Interactive-map-icons/icon-6.png);
}
.open-contribution .type {
    background-repeat: no-repeat;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position: 50%;
    height: 100px;
}
* {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
}
.open-contribution {
    max-width: 350px;
    min-width: 320px;
    cursor: pointer;
    color: #636e78;
    font-weight: normal;
    font-size: 12px;
}
.individual-contribution-page .individual-contribution-page-content{
    padding-top: 100px;
}
.individual-contribution-page .individual-contribution-page-content .right-header{
    height: 350px;
}
.individual-contribution-page .contribution-area .blue-button{
    margin: 80px 0 30px 0;
}
.individual-contribution-page .contribution-area h3.blue-button {
    font-size: 15px;
    line-height: 50px;
    padding: 0;
    font-family: "Montserrat",sans-serif;
    font-weight: bold;
}

.yj-yam-spittle {
    width: 13px !important;
    height: 11px;
    display: none!important;
    background-image: url('/images/25x25/3fa3f5.png?v1.0g');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle !important;
}
span.yj-share-copy {
    vertical-align: middle !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    display: none;
}
span.yj-share-copy:hover {
    color: #fff !important;
    text-decoration: none !important;
    display: none;
}
.yj-default-share-button {
    /* BUTTON SIZE */
    background-color: transparent !important;
    border-radius: 2px !important;
    display: inline-block !important;
    padding: 3px 6px;
    /* font */
    color: #fff !important;
    font-family: Helvetica !important;
    text-decoration: none !important;
    text-align: center !important;
    font-size: 11px !important;
    font-weight: bold !important;
    white-space: nowrap !important;
}

a .yj-default-share-button{
    background-image: url('/images/25x25/3fa3f5.png?v1.0g');
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle !important;
    background-color: transparent !important;
    border-radius: 2px !important;
    height: 49px !important;
    width: 50px !important;
    line-height: 46px !important;
    border-radius: 100% !important;
}
a .yj-default-share-button:hover{
    height: 49px !important;
    margin-bottom: 0!important;
    -webkit-filter: brightness(0) invert(1);
    -moz-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    border: none!important;
    -webkit-transition: .3s;
    transition: .3s;
}
.post-profile .post-profile-social-networks a.yammer{
    border: transparent;
}
.social-icon{
  color: #999;
  background-color: transparent;
  border: 1px solid #999;
  height: 48px;
  font-size: 23px;
  line-height: 48px;
  border-radius: 100%;
  width: 50px;
  margin-bottom: 15px;
  text-align: center;
  display: table;
  -webkit-transition: .3s;
  transition: .3s;
  cursor: pointer;
}
.individual-contribution-page .project-social-networks a {
    color: #999;
    background-color: transparent;
    height: 48px;
    font-size: 23px;
    line-height: 44px;
    border-radius: 100%;
    width: 50px;
    text-align: center;
    display: table;
    -webkit-transition: .3s;
    transition: .3s;
}
.individual-contribution-page .project-social-networks .twitter:hover, .post-profile .post-profile-social-networks .twitter:hover {
    background: #00aced;
    border-color: #00aced;
    color: white;
}
.individual-contribution-page .project-social-networks .facebook:hover, .post-profile .post-profile-social-networks .facebook:hover{
    background: #3b5998;
    border-color: #3b5998;
    color: white;
}
.individual-contribution-page .project-social-networks .email:hover, .post-profile .post-profile-social-networks .email:hover {
    background: #1cbbb4;
    border-color: #1cbbb4;
    color: white;
}
.individual-contribution-page .project-social-networks .linkedin:hover, .post-profile .post-profile-social-networks .linkedin:hover{
    background: #007bb5;
    border-color: #007bb5;
    color: white;
}
.contribute-register  button, .changePass .blue-button  {
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;
}
.individual-contribution-page-content img.contribution-img{
  display: none
}
/* CONTACTS PAGE*/
.Dialog-employeeProfile{
  background: rgba(51,173,82,0.95);
   background: -webkit-linear-gradient(right,rgba(51,173,82,0.95),rgba(6,156,149,0.95));
   background: -webkit-gradient(linear,right top, left top,from(rgba(51,173,82,0.95)),to(rgba(6,156,149,0.95)));
   background: linear-gradient(to left,rgba(51,173,82,0.95),rgba(6,156,149,0.95));
   top: 0;
   left: 0;
   position: fixed;
   z-index: 5000;
   padding: 15px 0;
   opacity: 1;
   visibility: visible;
   -webkit-transition: all linear .3s;
   transition: all linear .3s;
}

.DialogContent-employeeProfile img {
    float: right;
    border-radius: 100%;
    width: 250px;
    height: 250px;
}
.DialogContent-employeeProfile h1{
    color: #fff;
    font-size: 125px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 50px;
  }
.DialogContent-employeeProfile h2 {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    word-wrap: break-word;
    width: 50%;
}
.DialogContent-employeeProfile p {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    padding: 15px 0;
    height: auto;
}
.DialogContent-employeeProfile a {
    font-size: 21px;
    color: #fff;
    margin: 15px 15px 0 0;
}
.contacts .contacts-content .employee span {
    opacity: 1;
    visibility: visible;
    position: relative;
    width: 300px;
    height: 300px;
}
.contacts .contacts-content .employee .employee-social {
    position: relative;
    bottom: 65px;
    text-align: center;
    width: 100%;
    visibility: hidden;
}
.contacts .contacts-content .employee span>a{
    visibility: hidden;
    cursor: pointer;
}
.contacts .contacts-content .employee span:hover a{
    visibility: visible;
    -webkit-transition: .3s;
    transition: .3s;
}
.contacts .contacts-content .employee span:hover .employee-social{
    visibility: visible;
    -webkit-transition: .3s;
    transition: .3s;
}
.contacts .contacts-content .employee span:hover img{
    visibility: visible;
    -webkit-filter: brightness(50%);
    filter: brightness(60%);
    -webkit-transition: .3s;
    transition: .3s;
}
/* LOGIN MODAL*/
.login-modal .loginModal-footer{
    border-radius: 0 0 3px 3px;
    background: #d5e3eb;
    border-top: 1px solid #909ba4;
    padding: 10px;
    margin: 0px;
    display: block;
}
.login-modal .loginModal-content{
    border-radius: 0 0 3px 3px;
    background: #ebf0f3;
    border-top: 1px solid #909ba4;
    padding: 25px 0 25px 0;
}
.login-modal .loginModal-footer .btn-primary{
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    margin-right: 15px;
    border: 0;
    text-transform: uppercase;

}
  .login-modal .loginModal-content .btn-linkedin{
      color: #fff;
      background-color: #5bc0de;
      border-color: #46b8da;
      margin-top: 20px;
    }
    .login-modal .loginModal-close{
          border-bottom: 1px solid #e5e5e5;
          background: #203648;
    }
    .resetPassword{
      margin: 120px 0;
    }
    .textBottom-contribute{
      bottom: 18%;
      position: absolute;
      padding-left: 20px;
      padding-right: 30px;
    }

/* WELCOME */
    .welcome-page{
        margin-bottom: 100px;
    }
    .frontend-user-panel-menu, .members-menu{
        padding: 0px 25px;
    }
    .changePass{
      padding-top: 50px;
      background: #ebf0f3;
      height: 100%;
    }
    .member-document.page-animation.slide{
      background: #ebf0f3;
    }

/* Questionnaires */

.highlight-green{
   background: green;
   color: white;
}
.highlight-red{
  background: red;
}
/* READING LIST */
    .news-list .contribution-preview a img {
        width: 426px;
        height: 249px;
}
/* FORUM */
    .forum{
      padding: 0 15px;
    }
    .forum-threads {
        font-size: 15px;
    }
    .forum-threads .threads-head {
        font-size: 17px;
        background: #238892;
        color: #ffffff;
        height: 42px;
        line-height: 42px;
    }
    .forum-threads .threads-head:first-child {
        padding-left: 10px;
    }
    .forum-threads .thread-row {
        background: #ffffff;
        color: #2b3238;
        height: 42px;
        line-height: 42px;
        width: 100%
    }
    .forum-threads .thread-row:nth-child(even) {
        background: #f2f2f2;
    }

    .forum-threads .thread-row i.fa-chevron-right {
        margin-right: 15px;
    }

    .btn-undp {
        border-radius: 0 !important;
        margin-right: 10px;
    }
    .btn-default.btn-undp {
        border-color: #527073 !important;
        color: #527073 !important;
    }

    .btn-primary.btn-undp {
        background-color: #527073 !important;
        border-color: #527073 !important;
        text-transform: uppercase;
        margin-bottom: 10px;
        padding: 5px 15px;
        color: white;
        font-size: 14px;
        font-family: 'OpenSans',sans-serif;
}
    .forum-thread{
      background: #F2F2F2;
      padding: 10px;
      margin: 15px 15px 100px 15px!important;
    }
    .forum-thread .author-avatar{
      width: 80px;
      border-radius: 50%;
      border: 1px solid #f2f2f2;
      margin-top: 15px;
    }
    .action-buttons .btn-primary.btn-undp{
      margin-bottom:0px;
    }
    .action-buttons .btn-default{
      margin-right: 10px
    }
    .breadcrumb {
      background: none;
      font-family: 'ProximaNovaA', sans-serif;
    }
    .breadcrumb li span {
      color: #A3A9A8;
    }
    .breadcrumb li a {
      color: #143835;
      text-decoration: none;
    }
    .moduleSubMenu .breadcrumb{
      font-size: 20px;
    }
    .modal-content {
    background: #ebf0f3!important;
    padding: 0px!important;
    }
    .modal-header h2, .modal-title  h2{
      color: #fff;
      font-size: 24px;
    }
    .modal-content .modal-body {
      font-size: 16px;
    }
    .forum-message {
        border-bottom: 10px solid #F2F2F2;
        background: white;
    }
      h5.In.the.mediа{
            background: #ff5a00;
      }
      h5.Stories{
          background:#b21a7e
      }
      h5.SPHS.Publications{
        background:#01c9b8
      }
      h5.Voices{
        background:#123478
      }
      h5.NewsFlash{
        background:#509e3e
      }

      h5.In.the.media{
            background: #ff5a00;
      }

      /*** Panel - new design ***/
      a.profile-nav.alt, a:focus.profile-nav.alt{
         text-decoration:none;
      }
      .wdgt-value p{
          font-size: 12px;
          color: #8b8b8b;
      }

      .wdgt-value h1{
          font-size: 24px;
          font-weight: 600;
          margin: 0 0 15px 0;
          font-family: 'ProximaNovaA', sans-serif;
      }
      .profile-nav .user-heading {
          padding: 30px 30px 10px;
      }
      .profile-nav .user-heading i{
          color:#4eb0e2;
          font-size:50px;
      }
      .profile-nav .panel{
        background: white;
      }
      .panel-body.title-box{
          padding:0 0 25px 0px;
      }
      .panel > .panel-body a:hover{
          text-decoration:none;
      }
 /*** SHIPP ***/

 .tabsPage{
     padding: 50px 0px;
     display: flex;
 }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16.05.2019, 11:18:18
    Author     : Veronika Krasteva <veronikavalerieva@gmail.com>
*/

/*
  ##Device = Most of the Smartphones Mobiles
*/
@media (max-width: 767px){
    .homepage h1, .who-we-are-page .about h2, .heroshot .text-area h1, .asia-forum .asia-forum-social-area h2, .asia-forum .speakers h1,.programe a h1, .programe a h1 span {
    font-size: 30px !important;
    }
    .who-we-are-page .heroshot .text-area h1, .who-we-are-page .heroshot .text-area h4 {
    padding-left: 0;
    }
    .who-we-are-page .heroshot .bounce, .homepage .heroshot .bounce {
        display: none;
    }
    .homepage .heroshot .icon-scroll{
      display: none;
    }
    .footer-new .footer-top ul li {
      padding: 10px 0;
      border-bottom: 1px solid #3e5262;
    }
    .legend{
      display: none;
    }
    .filter-area .date-range{
      margin-bottom: 20px;
    position: static;
    }
    .footer-new .footer-top ul {
      display: block;
    }
    .contacts .heroshot .text-area h1 {
      font-size: 100px!important;
   }
   .Toastify__toast-container--top-center {
    top: 1em;
    left: 0;
    right: 0;
    margin-left: 0px;
    width: 100%!important;
  }
  .icon-login{
    padding: 0px!important;
  }
  .ag-theme-material .ag-paging-panel > span{
    margin-left: 0px;
  }
  .onlyMobile{
    display: block;
  }
  .blue-button.large{
    padding: 10px 40px;
    margin-top: 20px;
  }
  .asia-forum.virtual-forum .programme{
    padding: 50px 0px;
  }
  .speaker-slide {
    max-width: 169px;
  }
  .sponsors-box{
    display: grid;
  }
  .speakers-agenda {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2,1fr);
  }
}
/* Menu */
@media (max-width: 767px){
  .new-menu i.ion-log-in {
  display: table;
  }
  .members-menu .members-menuMobile{
    float: left!important;
    display: grid;
    text-align: left;
  }
  .mobileMenu-icon{
    -webkit-filter: invert(1) brightness(50%) sepia(100%) saturate(10000%);
    filter: invert(1) brightness(50%) sepia(100%) saturate(10000%);
    font-size: 45px;
  }
  .logoText{
    display: none;
  }
  .login-linkNew-icon {
    -webkit-filter: none;
    filter: none;
  }
}
/* Homepage */
@media (max-width: 767px){
  .homepage .heroshot {
    background: #203648;
    display: table;
    width: 100%;
  }
  .homepage .heroshot img{
    display: none;
  }
  .homepage .heroshot .text-area {
    position: relative;
    -webkit-transform: none;
            transform: none;
    width: 100%;
    padding: 50px 2%;
    left: 0;
    }
  .homepage .homepage-members .five-column {
    width: 99% !important;
    margin: 7.5px .5%;
    }
    .homepage .homepage-locations .five-column {
      width: 49% !important;
    }
}

@media (max-width: 767px){
.filter-area .filter-column {
    display: table;
    width: 100%;
    float: left;
    margin: 0 45px 0 0;
    }
  .filter-area .blue-button {
    width: 100%;
  }
  .filter-area .searchRow {
    display: inline;
  }
  .filter-area .searchField{
    margin: 15px;
  }
  .event-calendar .preview-member-area {
    padding: 0;
  }
  .post-profile .post-profile-social-networks .social-shares {
    margin-bottom: 15px;
  }
  .registerContibute-form{
    padding: 0 10px;
  }
  .registerNotification-form{
    padding: 0 20px;
  }
  .topEmptySpace{
    display: none;
  }
  .DialogContent-employeeProfile h1{
    font-size: 36px;
    margin-bottom: 20px;
  }
  .DialogContent-employeeProfile h2{
    font-size: 24px;
    width: 100%;
  }
  .modal-header h2, .modal-title h2{
    font-size: 14px;
  }
  .modal-header button.modal-button-close{
    padding: 0px;
  }
  .ant-calendar-range{
    width: 100%
  }
  .ant-calendar-date-panel{
    display: inline-grid;
  }
  .ant-calendar-range-part{
    width: 100%
  }
  .tabsPage{
      display: grid;
  }
}
/* Menu */
@media (max-width: 1400px){
    .new-menu .main-menu-social-bar a, .new-menu .main-menu-social-bar .login-link{
      font-size: 11px!important;
    }
    .login-linkNew {
    font-size: 10px!important;
  }
}
@media (max-width: 1067px){
    .new-menu {
      position: absolute;
      top: 0;
      background: white!important;
      padding: 0;
      width: 100%
    }
    .new-menu i.ion-log-in {
      display: table
    }
    .new-menu .main-menu-social-bar.ng-hide {
      display: none !important
    }
    .new-menu .main-menu-social-bar {
      background: #203648;
      display: block;
      position: absolute;
      top: 75px;
      padding: 0 15px;
      width: 320px;
      border: 1px solid #999;
      right: 1px
    }
    .new-menu .main-menu-social-bar a {
      float: left;
      margin: 0 10px;
      margin-right: 0 !important;
      color: white!important;
      line-height: 60px;
      height: 60px;
    }
    .new-menu .main-menu-social-bar a.reading-list {
      margin-top: 14px
    }
    .new-menu .main-menu-social-bar .top-menu-social-bar {
      display: none
    }
    .new-menu .logo img {
      margin-left: 0px;
    }
    .new-menu .logo{
      padding-left: 0px;
    }
    .hide-menu .hide-menu-aside {
      width: 100%;
      overflow-y: scroll
    }
    .hide-menu .hide-menu-aside ul {
      height: auto !important;
      padding-top: 0
    }
    .hide-menu .hide-menu-aside ul li {
      height: auto !important;
      padding: 20px 0
    }
    .hide-menu .hide-menu-aside ul li a {
      height: auto !important
    }
    .hide-menu .hide-menu-aside ul.hide-menu-social-bar li {
      padding: 7.5px 0 0 0 !important
    }
    .hide-menu .hide-menu-aside .bottom-area {
      position: relative;
      margin-top: 15px
    }
    .textBottom-contribute{
      position: inherit;
    }

}
/* Who we are */
@media only screen and (max-width: 1076px) {
  .who-we-are-page .heroshot img, .individual-member-page .heroshot img {
    display: none;
    }
  .who-we-are-page .heroshot, .individual-member-page .heroshot {
    display: table;
    padding: 15px 0;
    background-color: #203648;
    }
  .who-we-are-page .heroshot .text-area, .individual-member-page .heroshot .text-area {
    position: relative;
    bottom: 0;
    padding-left: 0;
    position: relative;
    top: 15px;
    padding-left: 10px;
    }
    .who-we-are-page .heroshot .text-area h1, .individual-member-page .heroshot .text-area h1 {
    padding-left: 0;
    }
    .who-we-are-page .heroshot .text-area h2, .individual-member-page .heroshot .text-area h2{
    font-size: 21px;
    }
    .who-we-are-page .heroshot .text-area h4, .individual-member-page .heroshot .text-area h4{
    font-size: 21px;
    padding-left: 0;
    }
   .who-we-are-page .about img {
    padding: 25px 0;
    }
    .who-we-are-page .image-area .image-area-thumb img {
    height: 100% !important;
    }
    .who-we-are-page .image-area .image-area-thumb .image-hover {
    visibility: visible;
    opacity: 1;
    background: rgba(0,0,0,0.5);
    }
    .who-we-are-page .image-area .image-area-thumb .image-hover hr, .who-we-are-page .image-area .image-area-thumb .image-hover p {
    visibility: visible;
    opacity: 1;
    }
    .who-we-are-page .about.background-right, .who-we-are-page .about.background-left {
    padding: 15px 0;
    }
}
@media only screen and (max-width: 1076px) {
  .news-list .heroshot>img,.focus-area-page .heroshot>img,.asia-forum .heroshot>img,.event-calendar .heroshot>img, .contacts .heroshot>img {
    display: none;
}
  .news-list .heroshot .text-area, .focus-area-page .heroshot .text-area, .asia-forum .heroshot .text-area, .event-calendar .heroshot .text-area,.contacts .heroshot .text-area {
    position: relative;
    bottom: 0;
    top: 0;
  }
  .contacts .heroshot .text-area {
    top: 30px;
}
  .news-list .heroshot, .focus-area-page .heroshot, .asia-forum .heroshot, .event-calendar .heroshot, .contacts .heroshot {
    display: table;
    width: 100%;
    padding: 75px 0 50px 0;
    background: #203648;
    }
    .right-header {
      background: #1cbbb4;
      padding: 15px;
      display: table;
      width: 100%;
      height: auto !important;
    }
    .post-profile .author-profile-information {
      bottom: 80px;
      padding: 0 20px 0 20px;
      position: relative;
    }
    .post-profile .author-profile-information p{
      color: white;
    }
    .post-profile .author-profile-information h5{
      color: white;
    }
    .map-height{
        height: 500px;
    }
    .homepage-news .max-width{
    display: inherit;
    }

    .forum-threads .thread-row {
      font-size: 12px;
      height: auto;
      line-height: 24px;
    }
    .forum-threads .threads-head {
      font-size: 12px;
    }
}
@media only screen and (max-width: 1090px) {
  .who-we-are-page .heroshot .text-area{
    bottom: 0;
    }
  .homepage .heroshot .icon-scroll{
      display: none;
    }
    .news-list .heroshot .text-area img, .focus-area-page .heroshot .text-area img, .asia-forum .heroshot .text-area img, .event-calendar .heroshot .text-area img, .contacts .heroshot .text-area img {
      width: 130px;
      }
}
/*
  ##Device = Desktops
*/
@media screen and (min-width: 1360px){
  .homepage .homepage-members .five-column {
    width: 19%;
  }
}
@media (max-width: 1366px){
    .max-width {
      max-width: 100% !important;
    }
}
@media only screen and (min-width: 1800px) {
    .who-we-are-page .image-area-thumb, .who-we-are-page .image-area-blue-box, .who-we-are-page .image-area .image-area-thumb img{
    height: 450px;
    }
}
/* ADMIN */
@media (max-width: 767px){
  .moduleSubMenu .breadcrumb{
    margin-left: 10px;
  }
  .rbc-toolbar {
    display: inline-grid;
  }
  .rbc-toolbar .rbc-toolbar-label{
    font-size: 20px;
    margin: 15px 0px;
  }
  .user-member-list .filter-area .filter-search-box{
    float: none!important;
    margin: auto;
  }
  .frontend-user-panel a.logout{
        margin: 9px 0px;
  }
  .mobileTable td, .mobileTable th{
    padding: 4px;
  }
  .mobileMargin10{
    margin: 10px;
  }
  .customButtons .btn{
    white-space: normal;
  }
  .toolbar{
    display: block!important;
  }
  .select2-position{
    width: 60%;
  }
   .customDialog .MuiDialog-paperWidthMd{
     height: inherit;
   }
}

.App {
    /* text-align: center;*/
}

html {
    font-size: 100% !important; /* 62.5% of 16px = 10px */
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.asia-forum img{
    width: 108px;
}

img {
    width: 100%;
}

.landing {
    position: relative;
    background: url(/static/media/showcase.4b31330b.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    margin-top: -24px;
    margin-bottom: -50px;
}

.landing-inner {
    padding-top: 80px;
}

.dark-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-form {
    opacity: 0.9;
}

.latest-profiles-img {
    width: 40px;
    height: 40px;
}

.form-control::-webkit-input-placeholder {
    color: #bbb !important;
}

.form-control::-moz-placeholder {
    color: #bbb !important;
}

.form-control::-ms-input-placeholder {
    color: #bbb !important;
}

.form-control::placeholder {
    color: #bbb !important;
}

.modalConfirm{
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: 60%;
    max-width: 500px;
    min-height: 100px;
    margin: 0 auto;
    padding: 30px;
    overflow-y: scroll;
}

.backendropss {
    z-index: 100000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.55);
    overflow-y: scroll;
    width: 100%;
}


.w3-border {
    border: 1px solid #ccc!important;
    border-radius: 16px;
}

.bold {
    font-weight: bold;
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.who-we-are-page .image-area .image-area-blue-box p{
    padding: '5px'
}

.post-profile .right-header{
    position: relative;
}

.post-profile .author-profile{
    top: 0px;
}





.dialog-title-h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 3.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.00833em;
}

.tox-notifications-container {display:none!important;}

.error {
    color: red;
}
.green-button {
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;

}
.labelRoot {
    fontSize: 14px;
}

.textField {
    background: white;
    border-radius: 4px;
}

.textRoot{
    background: #ebf0f3;
    margin: 0;
    padding-top: 10px;
    font-size: 14px;
}
.formControl {
    background: #ffffff;
    width: 100%;
    padding: 0 15px;
    height: 48px;
    border-radius: 4px!important;
    border: 1px solid #c7c7c7!important;
    margin-top: 16px!important;
    margin-bottom: 8px!important;
}

.inputField {
    background: white;
    border-radius: 4px;
    font-size: 14px;
}

.labelRoot1 {
    fontSize: 14px;
    z-index: 10;
}
.resetPass{
    padding-top: 50px;
    background: #ebf0f3;
    height: 100%;
}

.contribute-reset {
    display: table;
    background: #ebf0f3;
    width: 100%;
    min-height: 100%;
}

.contribute-reset .header {
    background: #203648;
    padding: 90px 0 50px 0;
    width: 100%;
    display: table;
}

.contribute-reset .blue-button {
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;
}

.toast-error-wide {
    width: 420px;
    height: 100px;
}

.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    width: 420px;
    box-sizing: border-box;
    color: #fff;
}

.tmc-reply, div.mce-edit-area {
    height: auto;
    min-height: 300px;
}

.margin-top {
    margin-top: 30px;
}
.margin-top15 {
    margin-top: 15px;
}
.m-top20 {
    margin-top: 20px!important;
}
.m-top30 {
    margin-top: 30px;
}
.m-left10{
    margin-left: 10px;
}
.m-all20 {
    margin: 20px;
}
.m-20 {
    margin: 0  20px 20px!important;
}
.m-bot30 {
    margin-bottom: 30px!important;
}
.m-bot15 {
    margin-bottom: 15px!important;
}
.m-all30 {
    margin: 30px;
}
.p-bot30 {
    padding-bottom: 30px;
}
.p-all20 {
    padding: 20px;
}
.p-lr15{
    padding: 0px 15px;
}
.p-lr30{
    padding: 0px 30px;
}
.align-center{
    align-items: center;
}
body.noscroll{
    position:fixed;
    overflow:hidden;
}

.contribute-register button.dropzone-button {
    background-color: #ffffff;
    border-radius: 3px;
    color: black;
    cursor: pointer;
    padding: 0;
    width: 25px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;
}

.Difference {
    font-family: monospace;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-style: italic;
    font-weight: bold;
}

.Difference > del {
    background-color: rgb(204, 204, 0);
    text-decoration: none;
    margin-right: 10px
}

.Difference > ins {
    background-color: rgb(201, 238, 211);
    text-decoration: none;
}


.edit-contribution .textBottom-contribute{
    bottom: 0;
    position: relative;
    padding-left: 20px;
    padding-right: 30px;;
}

.monthly-stats {
    border-radius: 4px 4px 0px 0px;
    margin: -15px -15px 15px -15px;
    padding: 15px;
}
.mce-statusbar>.mce-container-body .mce-path, .mce-branding{
    display: none!important;
}
button[disabled].button-submit {
    background-color: #ccc !important;
    border-color: #ccc !important;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 30%;
    right: 30%;
    margin-left: -160px;
    width: 50% !important;
    font-size: 16px;
}
.Toastify__toast--default {
    padding: 30px;
}
.help-content ul li {
    list-style: disc!important;
    margin-left: 30px;
    margin-bottom: 1em;
}
.help-content img{
   max-width: 100%;
}
.togglePanel {
    width: 140px;
    padding: 11px 12px 13px 16px;
    left: 100%;
    color: #fff;
    font-size: 13px;
    border-radius: 0 100px 100px 0;
    text-align: left;
}

.sortFields {
    display: flex;
    -webkit-box-align: center;
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #333;
    font-weight: 400;
    font-family: Montserrat,Helvetica Neue,Helvetica,arial,sans-serif;
    height: 59px;
    z-index: 10000 !important;
    padding: 15px !important;
    cursor: move;
}

.sortableHelper {
    z-index: 10000 !important;
}

.error-alert {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
    display: flex;
    padding: 20px 16px 10px 10px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    margin-top: 50px;
}
.paper {
    padding: 15px;
    text-align: 'left';
    width: 100%;
    height: 100%;
    color: '#000000de';
  }
  
#mceu_53 {
  display: none;
}
 
.tox-statusbar__text-container{
    display: none !important;
}

.post-profile .author-profile-information {
    position: absolute;
    bottom: 6px;
  }

