.settings_message {
   /* position: fixed;
    bottom: -20px;*/
    width: 100%;
  /*  height: 60px;*/
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.settings_message > p {
    background: #44c699;
    width: 50%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  /*  height: 40px;*/
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 10px 10px;
}

.settings_message > p.error {
    background: #e50000;
}