.paid {
    background-color: green;
    border-radius: 4px;
    padding: 3px 15px;
}

.unpaid {
    background-color: red;
    border-radius: 4px;
    padding: 3px 15px;
}

.partialypaid {
    background-color: yellow;
    border-radius: 4px;
    padding: 3px 15px;
}

.table-inbox {
    position: relative;
    overflow: scroll;
    margin: 0;
    background: #f8f9fc;
    border-radius: 4px;
    font-size: 14px;
    min-width: 958px;
    height: 63vh;
    margin-top: 15px;
}
.table-inbox a{
    color: #32323a;
}
.table-inbox a:hover{
    color: #222227;
    text-decoration: underline;
}
.ag-body-viewport {
    overflow-x: scroll;
    overflow-y: scroll !important;
}

.nav.nav-pills li a {
    color: #4eb0e2;
    padding-left: 0;
    padding-top: 5px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'ProximaNovaA', sans-serif;
    float: left;
    position: relative;
    display: block;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin-top: 10px;
}
.nav > h3.module-title{
    font-family: ProximaNovaA, sans-serif;
}
.ag-react-container>span>img {
    width: 55px;
}
#ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown{
    top: -10px;
}
#ag-grid-div > div > div:nth-child(6) > div.col-md-12{
    overflow-y: hidden!important;
}
#ag-grid-div > div > div:nth-child(5) > div.col-md-12{
    overflow-y: hidden!important;
}
#ag-grid-div > div > div:nth-child(6) > div.col-md-12 > div{
    overflow-y: hidden!important;
}

.table-inbox {
    overflow: hidden!important;
}
.dropdown.kit-grid-buttons {
    width: auto;
    margin-bottom: -30px;
}
#ag-grid-div .nav-link, #ag-grid-div .nav-link:link, #ag-grid-div .nav-link:visited, #ag-grid-div .nav-link:active {
    font-size: 14px!important;
}
#ag-grid-div .btn-group {
    width: auto;
    position: absolute;
    top: 1px;
    margin-left: 50px;
}
#ag-grid-div .btn-group button {
    border: 1px solid #44c699;
    background: #44c699;
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    text-transform: uppercase;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    cursor: pointer;
    width: 90px;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle{
    width: 125px;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle > .caret{
    margin-left: 10px;
}

#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle:hover {
    text-decoration: none;
    background: #38b88c !important;
}
li.nav-item> div > ul.dropdown-menu {
    background: #fff;
    border-radius: 3px;
    line-height: 1.46667;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    font-family: 'ProximaNovaA', sans-serif;
    color: #444;
    font-size: 16px;
    padding: 0;
}
li.nav-item> div > ul.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
    color: #333;
}
.nav-link>span>i {
    margin-right: 6px;
}
.dropdown.kit-grid-buttons button {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 10px;
    left: 0;
}

.dropdown.kit-grid-buttons .dropdown-menus {
    top: 30px;
    left: 10px;
}

.dropdown-menus {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    /* display: none;*/
    float: left;
    min-width: 160px;
    padding: 5px;
    margin: 5px;
    list-style: none;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
}

.deletedButt {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#ag-grid-div input[type="text"] {

    height: 40px;
    /* line-height: 40px; */
    /* padding: 0 5px; */
    /* float: left; */
    /* border-radius: 4px; */
    /* color: #444; */
    /* font-weight: 300; */
    /* font-size: 16px; */
    background: transparent;
    /* min-width: 320px; */
    /* padding: 10px 0; */
    /* margin-bottom: 15px; */
    outline: 0;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

}

.marg {
    margin: 10px;
    cursor: pointer;
}

.marg-bottom {
    margin-bottom: 10px;
}

.ag-theme-balham .ag-header-cell-resize {
    position: absolute;
    right: -4px;
    width: 8px;
    z-index: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.kit-grid-button {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
}

.kit-grid-but {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-color: #b0b5b9;
    border-color: #b0b5b9;
    color: #fff;
}

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.dropdown-toggle:focus {
    outline: 0;
}

.btn:hover, .btn:focus {
    color: #333;
    text-decoration: none;
}

.menu {
    z-index: 100;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}
.modalConfirms i.fa.fa-close {
    margin: 15px;
}
.modalConfirms input[type="text"],
.modalConfirms input[type="password"],
.modalConfirms input[type="email"],
.modalConfirms input[type="number"],
.modalConfirms select.form-control,
.modalConfirms .ant-calendar-picker-input{
    min-width: 230px!important;
}

.date-range{
    margin-top: 9px!important;
    margin-bottom: 10px;
    text-align: center;
}

.date-range input {
    cursor: pointer !important;
}

.date-range div {
    border-radius: 4px;
}

.date-range .ant-calendar-picker{
    width: 250px!important;
    min-width: 0px!important;
}

.date-range .ant-calendar-picker-input{
    width: 250px!important;
    min-width: 0px!important;
    font-size: 14px;
    color: rgb(0, 0, 0, 0.65);
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: normal;
    margin-bottom: 0;
}

.date-range .ant-calendar-range-picker-separator{
    vertical-align: baseline!important;
}

.date-range .ant-calendar-picker-clear,
.date-range .ant-calendar-picker-icon{
    top: 65%;
}

.date {
    margin-top: 12px!important;
    text-align: center;
}

.date .ant-calendar-picker{
    width: 150px!important;
    min-width: 0px!important;
}

.date .ant-calendar-picker-input{
    width: 150px!important;
    min-width: 0px!important;
    font-size: 14px;
    padding-left: 10px!important;
    color: rgb(0, 0, 0, 0.65);
    border: none;
    padding-top: 0;

    padding-left: 21px!important;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: normal;
}

.date .ant-calendar-picker-clear,
.date .ant-calendar-picker-icon{
    top:37%!important;
}

.modalConfirms input[type="text"],
.modalConfirms input[type="password"],
.modalConfirms input[type="email"],
.modalConfirms input[type="number"],
.modalConfirms select.form-control,
.modalConfirms .ant-calendar-picker-input{
    min-width: 230px!important;
}

.backendropss {
    z-index: 100000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
}

.modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 60%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
}
.modalS > div.text-right > div > i{
    color: lightgray;
}

ul li.list {
    margin-left: 15px;
}

#ag-grid-div .dropdown-menu-select {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    /*   display: none;*/
    /* float: left;*/
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
}

/* Navigation */

#ag-grid-div .nav {
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
    padding-left: 15px;
}

#ag-grid-div .nav-items {
    padding: 0;
    list-style: none;
    float: left;
}

#ag-grid-div .nav-item {
    display: inline-block;
    margin-right: -15px;
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 14px;
}

#ag-grid-div .nav-item >a>i {
    font-size: 20px;
    position: absolute;
}
#ag-grid-div .nav-item.dropdown >a >svg{
    font-size: 20px;
    position: absolute;
    color: rgb(165, 163, 163);
    top: -26px
}
#ag-grid-div .nav-item.dropdown.add >a>i {
    top: -2px;
}

#ag-grid-div .nav-item >a>i.ion-plus-round {
    display: none;
}

#ag-grid-div .nav-link,
#ag-grid-div .nav-link:link,
#ag-grid-div .nav-link:visited,
#ag-grid-div .nav-link:active,
#ag-grid-div .submenus-link,
#ag-grid-div .submenus-link:link,
#ag-grid-div .submenus-link:visited,
#ag-grid-div .submenus-link:active {
    display: block;
    position: relative;
    font-size: 16px;
    /* letter-spacing: 1 px;*/
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

#ag-grid-div .nav-link,
#ag-grid-div .nav-link:link,
#ag-grid-div .nav-link:visited,
#ag-grid-div .nav-link:active {
    color: #4eb0e2;
    font-weight: bold;
    /*    text-transform: uppercase;*/
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
}

#ag-grid-div .nav-link::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* height: 3 px;*/
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

#ag-grid-div .nav-link:hover::before,
#ag-grid-div .nav-link:hover::before {
    opacity: 1;
    -webkit-transform: translate(0, 5px);
    transform: translate(0, 5px);
}

#ag-grid-div .dropdown {
    position: relative;
}

#ag-grid-div .dropdown .nav-link {
    padding-right: 15px;
    height: 17px;
    line-height: 17px;

}

#ag-grid-div .dropdown .nav-link::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    border: 5px solid transparent;
    border-top-color: #4eb0e2;
}

.submenus {
    position: absolute;
    top: 140%;
    left: 100%;
    z-index: 100;
    max-width: 200px;
    margin-left: -80px;
    background: #fff;
    border-radius: 3px;
    line-height: 1.46667;
    margin-top: -5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    pointer-events: auto;
    display: table;
}

.border-del {
    border-top: 1px solid #ccc !important;
    margin: 0;
}

.submenus-items {
    list-style: none;
    padding: 0;
    font-family: 'ProximaNovaA', sans-serif;
    color: #444;
    font-size: 12px;
    width: max-content;
}

#ag-grid-div .submenus-items .inner-list {
    z-index: 1!important;
    position: absolute;
    left: 100px;
    top: 0px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
}

#ag-grid-div .submenus-items i {
    margin-left: 3px;
    font-size: 12px;
}


.submenus-item {
    display: block;
    text-align: left;

}

.submenus-link,
.submenus-link:link,
.submenus-link:visited,
.submenus-link:active {
    color: #444;
    padding: 4px 20px;
    font-size: 16px;
    font-family: 'ProximaNovaA', sans-serif;
}

.submenus-link:hover {
    text-decoration: underline;
}

.submenus-seperator {
    height: 0;
    margin: 12px 10px;
    border-top: 1px solid #eee;
}

.show-submenus .submenus {
    opacity: 1;
    pointer-events: auto;
}

.submenus-link:hover {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    color: #444;
}

select {
/*    -webkit-appearance: none;
    -moz-appearance: none;*/

}

.selectDots {
    color: #444;
    background: transparent !important;
    border: none !important;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    margin-left: 20px;
}

.option-link {
    color: #444;
    padding: 10px 20px;
    font-size: 12px;
    font-family: 'ProximaNovaA', sans-serif;
    background-color: white !important;
    -moz-appearance: none;
}

ul li ol li {
    list-style: none;
}

li.dropdownCell a {
    margin-left: 10px;
    /* padding: 10 px;*/
}

li.dropdownCell:hover {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
}

.dropdownCellLink {
    margin-left: 10px;
    text-decoration: none;
}

.dropdownCellLink:hover {
    margin-left: 10px;
    text-decoration: none;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
    outline-offset: 0;
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 80px;
    vertical-align: middle;
    margin-right: 15px;
}

input[type="text"] {
    min-width: 120px;
}

#ag-grid-div .col-xs-1, #ag-grid-div .col-sm-1, #ag-grid-div .col-md-1, #ag-grid-div .col-lg-1,
#ag-grid-div .col-xs-2, #ag-grid-div .col-sm-2, #ag-grid-div .col-md-2, #ag-grid-div .col-lg-2,
#ag-grid-div .col-xs-3, #ag-grid-div .col-sm-3, #ag-grid-div .col-md-3, #ag-grid-div .col-lg-3,
#ag-grid-div .col-xs-4, #ag-grid-div .col-sm-4, #ag-grid-div .col-md-4, #ag-grid-div .col-lg-4,
#ag-grid-div .col-xs-5, #ag-grid-div .col-sm-5, #ag-grid-div .col-md-5, #ag-grid-div .col-lg-5,
#ag-grid-div .col-xs-6, #ag-grid-div .col-sm-6, #ag-grid-div .col-md-6, #ag-grid-div .col-lg-6,
#ag-grid-div .col-xs-7, #ag-grid-div .col-sm-7, #ag-grid-div .col-md-7, #ag-grid-div .col-lg-7,
#ag-grid-div .col-xs-8, #ag-grid-div .col-sm-8, #ag-grid-div .col-md-8, #ag-grid-div .col-lg-8,
#ag-grid-div .col-xs-9, #ag-grid-div .col-sm-9, #ag-grid-div .col-md-9, #ag-grid-div .col-lg-9,
#ag-grid-div .col-xs-10, #ag-grid-div .col-sm-10, #ag-grid-div .col-md-10, #ag-grid-div .col-lg-10,
#ag-grid-div .col-xs-11, #ag-grid-div .col-sm-11, #ag-grid-div .col-md-11, #ag-grid-div .col-lg-11,
#ag-grid-div .col-xs-12, #ag-grid-div .col-sm-12, #ag-grid-div .col-md-12, #ag-grid-div .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
}

.search-field-container {
    position: absolute;
    right: 0;
}

.search-field {
    position: relative;
    float: right;
    background: white;
    min-width: 240px;
    background: #fff;
    height: 40px;
    margin-top: 7px;
}

.search-field i.fa.fa-search {
    position: absolute;
    left: 28px;
    top: 15px;
    color: #a6a6a6;
}
.search-field i.fa.fa-times {
    cursor: pointer;
    position: absolute;
    right: -13px;
    top: 15px;
    color: #a6a6a6;
    font-size: 11px;
}

input.search-field-input {
    float: right !important;
    width: 80% !important;
    border-bottom: 1px solid #32323A!important;
    border-radius: 0!important;
    height: 30px!important;
    padding: 0!important;
}
input.search-field-input:active,
input.search-field-input:focus {
    border-bottom: 2px solid rgb(68, 198, 153)!important;
}

.goto-field {
    background: white;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    height: 40px;
    padding: 0;
    width: 40px;
    margin-top: 10px;
    margin-left: 13px;
}

.DateRangePicker {
    position: relative;
    display: inline-block;
}
.DateRangePickerInput__withBorder {
    border: 0!important;
}
.columns-container {
    margin-right: 30px;
    position: relative;
    display: inline-block;
}

.columns-button {
    margin-left: 0;
    margin-top: 10px;
    background: transparent;
    border-radius: 4px;
    border: none;
    height: 40px;
    line-height: 40px;
}

.columns-button-open {
    cursor: pointer;
/*    padding-left: 22px;*/
}
.columns-button-open > a>i{
    color: rgb(166, 166, 166);
}

.columns-button span {
    margin-left: 5px;
}

.columns-button a {
    padding: 0;
    text-align: center;
    font-size: 14px;
}

.columns-button a:hover, .columns-button a:active {
    text-decoration: none;
}

.grid-settings {
    position: relative;
    top: 19px;
    left: 15px;
}
.grid-settings>i{
    color: rgb(166, 166, 166);
}

.check-fa {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
}

.button-primary-new {
    text-transform: uppercase;
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 50px;
    height: 35px;
    /* float: left;*/
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    /* margin: 15 px;*/
    min-width: 125px;
    cursor: pointer;
}
.button-primary-new:hover, .button-primary:hover {
    text-decoration: none;
    background: #38b88c !important;
}
.fontStyle {
    font-family: 'ProximaNovaA', sans-serif;
}

.ag-dnd-ghost {
    display: none !important;
}

.booleanTrue {
    color: green;
}

.booleanFalse {
    color: red;
}

.ag-react-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ag-theme-balham .ag-root {
    border: 0!important;
}
/*Problem with calendar in grids*/
/*.ag-theme-balham .ag-row-selected, .ag-theme-balham .ag-row-hover, .ag-theme-balham .ag-row-focus {
    z-index: 0;
}*/

.grid-overflow-column {
    overflow: visible;
}

.selected-rows-count {
    font-style: italic;
}
.selected-rows-count>sup {
    top: -0.7em;
    margin-left: 5px;
    font-style: normal;
}
.selected-rows-count:hover {
    text-decoration: line-through;
    cursor: pointer;
}
.grid-panel {
    background: white;
    border-radius: 10px;
    width: 99%!important;
    margin: 7px 0 5px 5px;
    padding: 0;
}

.backendropss.transparent-back {
    background-color: transparent;
}
.modalConfirms.scroll.column-selector {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: 8px;
    top: 183px;
    width: 18%;
    max-height: 350px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
}
.modalConfirms.scroll.column-selector > section > div > div > ul {
    margin-bottom: 0px;
}
.modalConfirms.scroll.column-selector>div>div>i {
    display: none;
}

.modalConfirms.scroll.column-selector>.newPanel>.panel-body {
    padding-bottom: 0;
    padding-left: 5px;
}

.modalConfirms.scroll.column-selector>.newPanel>.panel-body>row>ul {
    margin-bottom:0;
}
.column-selector-item,
.column-selector-item>div>ul>li {
    margin-top: -15px;
    min-width: 200px;
}
.checkmark {
    height: 15px;
    width: 15px;
    border-radius: 0;
}
.custom-checkbox {
    padding-left: 25px;
    font-size: 16px;
}
.column-selector-item .custom-checkbox input:checked ~ .checkmark {
    background-color: rgb(68, 198, 153)!important;
    border-radius: 0!important;
}
.custom-checkbox input:checked ~ .checkmark {
    border-radius: 0!important;
}
.custom-checkbox:hover input ~ .checkmark {
    border-radius: 0!important;
}
.custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 4px;
    height: 10px;
}

.bulk-container {
    padding-left: 0!important;
    margin-top: 22px;
    width: 10%;
}
.field {
    height: 100px;
}

.ant-calendar-picker, .ant-calendar-picker-input {
    width: 100%!important;
    line-height: 1.5;
}

@media only screen and (max-height: 1080px) {
    .table-inbox {
        height: 77vh;
    }
    .newButton-mobile{
      position: inherit!important;
    }
}
@media only screen and (max-height: 900px) {
    .table-inbox {
        height: 75vh;
    }
}

@media only screen and (max-height: 864px) {
    .table-inbox {
        height: 64vh;
    }
}

@media only screen and (max-height: 800px) {
    .table-inbox {
        height: 71vh;
    }
}

@media only screen and (max-height: 768px) {
    .table-inbox {
        height: 64vh;
    }
}

@media only screen and (max-height: 720px) {
    .table-inbox {
        height: 57vh;
    }
}

@media only screen and ( max-width: 767px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
        padding-right: 0;
    }
    .form-group {
        width: 100%;
        margin-right: 0!important;
        margin-left: 0!important;
    }
    .ag-theme-balham.table-inbox {
        margin-left: 0;
        height: 77vh;
    }
    #ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown{
        position: fixed;
        left: 165px;
        top: 25px!important;
        z-index: 9999;
    }
    #ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown > a {
        color: white;
    }
    #ag-grid-div > div > div:nth-child(2) > div > nav {
        position: absolute;
        z-index: 99;
        top: -52px;
    }
    #ag-grid-div > div > div.col-md-3.ol-lg-2 > div > nav {
        margin-top: -51px;
        margin-left: 51px;
    }
    .columns-container {
        position: absolute;
        right: 0;
    }
    .search-field {
        min-width: 225px;
    }
    .search-field i.fa.fa-search {
        left: 13px;
    }
    .modalConfirms {
        width: 100%!important;
    }
    .bulk-container {
        width: auto;
    }
    .nav-items {
        padding-right: 5px;
    }
    .nav-item {
        padding-right: 5px;
    }
    #ag-grid-div .nav-item >a>i.ion-plus-round {
        left: 10px;
        display: block;
    }
    .columns-button {
        margin-top: 0;
        line-height: 0;
        margin-left: 72px;
    }
    .search-field-container {
        right: 10px!important;
        top: 0;
        margin-bottom: 10px;
    }
    .modalConfirms.scroll.column-selector {
        width: 50%;
        top: 125px;
    }
    .column-selector-item, .column-selector-item>div>ul>li {
        margin-top: 25px;
    }
    .custom-checkbox > .checkmark {
        margin-top: -6px;
    }
    .modalConfirms.scroll.column-selector>.newPanel>.panel-body {
        padding-top: 0;
    }
    #main-content.merge-left {
        float: none;
        width: 100%;
    }
    .date-range {
        margin-left: 0px!important;
        position: absolute;
        top: 0;
    }
    .columns-button {
        margin-top: 25px;
    }
    #ag-grid-div .nav-item >a>i.ion-plus-round {
        top: 3px;
        left: 10px;
    }
    #ag-grid-div .btn-group {
        position: relative;
    }
    #ag-grid-div .btn-group button.btn.btn-success {
        width: 50px;
    }
    #ag-grid-div .nav-item.dropdown.add {
        position: absolute;
        top: -12px;
    }
    li.nav-item> div > ul.dropdown-menu {
        margin-left: -80px;
    }
      /*  #ag-grid-div .nav-item.dropdown >a >svg{
      display: none;
    }*/

    /*    FORMS*/
    .hide-field {
        display: none;
    }
    #reactForm > div > div > section.panel
    {
        display: block;
        width: 100%
    }
    #reactForm > div > div > section>.panel-body
    {
        display: block;
        width: 100%;
        padding-right: 15px;
    }
    .ant-calendar-picker-input {
        min-width: 200px;
    }
    input[type="number"] {
        min-width: 200px;
    }
    .newMobile{
      z-index: 9999!important;
      position: fixed!important;
      top: 50px!important;
      left: 230px!important;
      color: white!important;
      font-size: 18px!important;
    }
}

@media only screen and (min-width: 1536px) {

    input.search-field-input {
        float: right !important;
        width: 80% !important;
    }

    .columns-button {
        margin-left: 0;
        font-size: 12px;
        margin-top: 10px;
    }
}

.ant-calendar-range-picker-input {
    background-color: transparent;
    border: 0;
    height: 99%;
    outline: 0;
    width: 44%;
    text-align: center;
}

.date-range .ant-calendar-picker-clear, .date-range .ant-calendar-picker-icon {
    top: 65%;
}

.ant-calendar-picker-clear {
    opacity: 0;
    z-index: 1;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
    pointer-events: none;
    cursor: pointer;
}
.ant-calendar-picker-clear, .ant-calendar-picker-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    right: 12px;
    top: 50%;
    margin-top: -7px;
    line-height: 14px;
    font-size: 12px;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#ag-grid-div .nav {
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
    padding-left: 15px;
        display: block;
}

#ag-grid-div .nav-item {
    display: inline-block;
    margin-right: -15px;
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 14px;
}

.nav>li {
    position: relative;
    display: block;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.custom-tooltip {
  position: absolute !important;
  width: 150px;
  height: 70px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.ag-tooltip {
    position: absolute !important;
    pointer-events: none !important;
    z-index: 9009999 !important;
    background: pink !important;
}
/* .ag-tooltip {
        background-color: white !important;
        color: black;
        border-radius:  2px !important;
        padding: 15px !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: #ebebeb !important;
       

        &.ag-tooltip-hiding {
            opacity: 0;
        }
    }*/