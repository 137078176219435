.loaderImage {
    margin-top: 20px;
    animation: fade 0.2s linear;
}

@keyframes fade {
    0% { margin-top: 40px; }
    50% { margin-top: 30px; }
    100% { margin-top: 20px; }
}

@keyframes gridSlide {
    0% { margin-top: 20px; }
    50% { margin-top: 10px; }
    100% { margin-top: 0px; }
}

.gridLoadRow {
    animation: gridSlide 0.2s linear;
}

.gridLoadRow .rowCheckbox {
    float: left;
    width: 16px;
    height: 16px;
    margin: 2px 30px 0px 40px;
}

.gridLoadRow .gridRow {
    float: left;
    width: 90%;
    height: 20px;
    border: none;
    border-radius: 0;
    opacity: 0.4;
}

@media only screen and (max-width: 1100px) {
    .gridLoadRow .gridRow {
        width: 80%;
    }
}

@media only screen and (max-width: 700px) {
    .gridLoadRow .gridRow {
        width: 70%;
    }
}


.even {
    background-color: #d2dbe1;
}

.odd {
    background-color: #F2F2F2;
}

.even, .odd {
    border-radius: 5px;
}

.no-radius {
    border-radius: 0;
    border: 0 !important;
    background-color: #F2F2F2;
    height: 15px;
    margin-bottom: 15px;
}

@keyframes formSlide {
    0% { margin-top: 50px; }
    25% { margin-top: 45px; }
    50% { margin-top: 40px; }
    50% { margin-top: 35px; }
    100% { margin-top: 30px; }
}
.formLoadBlock {
    float: left;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    animation: formSlide 0.2s linear;
}

.formLoadBlock .fieldHolder {
    width: 100%;
    height: 100%;
    background-color: #f8f9fc;
    border-radius: 5px;
}