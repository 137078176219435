.App {
    /* text-align: center;*/
}

html {
    font-size: 100% !important; /* 62.5% of 16px = 10px */
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.asia-forum img{
    width: 108px;
}

img {
    width: 100%;
}

.landing {
    position: relative;
    background: url('./img/showcase.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    margin-top: -24px;
    margin-bottom: -50px;
}

.landing-inner {
    padding-top: 80px;
}

.dark-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-form {
    opacity: 0.9;
}

.latest-profiles-img {
    width: 40px;
    height: 40px;
}

.form-control::placeholder {
    color: #bbb !important;
}

.modalConfirm{
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: 60%;
    max-width: 500px;
    min-height: 100px;
    margin: 0 auto;
    padding: 30px;
    overflow-y: scroll;
}

.backendropss {
    z-index: 100000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.55);
    overflow-y: scroll;
    width: 100%;
}


.w3-border {
    border: 1px solid #ccc!important;
    border-radius: 16px;
}

.bold {
    font-weight: bold;
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.who-we-are-page .image-area .image-area-blue-box p{
    padding: '5px'
}

.post-profile .right-header{
    position: relative;
}

.post-profile .author-profile{
    top: 0px;
}





.dialog-title-h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 3.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.00833em;
}

.tox-notifications-container {display:none!important;}

.error {
    color: red;
}
.green-button {
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;

}
.labelRoot {
    fontSize: 14px;
}

.textField {
    background: white;
    border-radius: 4px;
}

.textRoot{
    background: #ebf0f3;
    margin: 0;
    padding-top: 10px;
    font-size: 14px;
}
.formControl {
    background: #ffffff;
    width: 100%;
    padding: 0 15px;
    height: 48px;
    border-radius: 4px!important;
    border: 1px solid #c7c7c7!important;
    margin-top: 16px!important;
    margin-bottom: 8px!important;
}

.inputField {
    background: white;
    border-radius: 4px;
    font-size: 14px;
}

.labelRoot1 {
    fontSize: 14px;
    z-index: 10;
}
.resetPass{
    padding-top: 50px;
    background: #ebf0f3;
    height: 100%;
}

.contribute-reset {
    display: table;
    background: #ebf0f3;
    width: 100%;
    min-height: 100%;
}

.contribute-reset .header {
    background: #203648;
    padding: 90px 0 50px 0;
    width: 100%;
    display: table;
}

.contribute-reset .blue-button {
    background-color: #1cbbb4;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    width: 175px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;
}

.toast-error-wide {
    width: 420px;
    height: 100px;
}

.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    width: 420px;
    box-sizing: border-box;
    color: #fff;
}

.tmc-reply, div.mce-edit-area {
    height: auto;
    min-height: 300px;
}

.margin-top {
    margin-top: 30px;
}
.margin-top15 {
    margin-top: 15px;
}
.m-top20 {
    margin-top: 20px!important;
}
.m-top30 {
    margin-top: 30px;
}
.m-left10{
    margin-left: 10px;
}
.m-all20 {
    margin: 20px;
}
.m-20 {
    margin: 0  20px 20px!important;
}
.m-bot30 {
    margin-bottom: 30px!important;
}
.m-bot15 {
    margin-bottom: 15px!important;
}
.m-all30 {
    margin: 30px;
}
.p-bot30 {
    padding-bottom: 30px;
}
.p-all20 {
    padding: 20px;
}
.p-lr15{
    padding: 0px 15px;
}
.p-lr30{
    padding: 0px 30px;
}
.align-center{
    align-items: center;
}
body.noscroll{
    position:fixed;
    overflow:hidden;
}

.contribute-register button.dropzone-button {
    background-color: #ffffff;
    border-radius: 3px;
    color: black;
    cursor: pointer;
    padding: 0;
    width: 25px;
    height: 35px;
    display: table;
    text-align: center;
    line-height: 35px;
    margin: 0 auto;
    border: 0;
    text-transform: uppercase;
}

.Difference {
    font-family: monospace;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-style: italic;
    font-weight: bold;
}

.Difference > del {
    background-color: rgb(204, 204, 0);
    text-decoration: none;
    margin-right: 10px
}

.Difference > ins {
    background-color: rgb(201, 238, 211);
    text-decoration: none;
}


.edit-contribution .textBottom-contribute{
    bottom: 0;
    position: relative;
    padding-left: 20px;
    padding-right: 30px;;
}

.monthly-stats {
    -webkit-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
    margin: -15px -15px 15px -15px;
    padding: 15px;
}
.mce-statusbar>.mce-container-body .mce-path, .mce-branding{
    display: none!important;
}
button[disabled].button-submit {
    background-color: #ccc !important;
    border-color: #ccc !important;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 30%;
    right: 30%;
    margin-left: -160px;
    width: 50% !important;
    font-size: 16px;
}
.Toastify__toast--default {
    padding: 30px;
}
.help-content ul li {
    list-style: disc!important;
    margin-left: 30px;
    margin-bottom: 1em;
}
.help-content img{
   max-width: 100%;
}
.togglePanel {
    width: 140px;
    padding: 11px 12px 13px 16px;
    left: 100%;
    color: #fff;
    font-size: 13px;
    border-radius: 0 100px 100px 0;
    text-align: left;
}

.sortFields {
    display: flex;
    -webkit-box-align: center;
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    user-select: none;
    color: #333;
    font-weight: 400;
    font-family: Montserrat,Helvetica Neue,Helvetica,arial,sans-serif;
    height: 59px;
    z-index: 10000 !important;
    padding: 15px !important;
    cursor: move;
}

.sortableHelper {
    z-index: 10000 !important;
}

.error-alert {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
    display: flex;
    padding: 20px 16px 10px 10px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    margin-top: 50px;
}
.paper {
    padding: 15px;
    text-align: 'left';
    width: 100%;
    height: 100%;
    color: '#000000de';
  }
  
#mceu_53 {
  display: none;
}
 
.tox-statusbar__text-container{
    display: none !important;
}

.post-profile .author-profile-information {
    position: absolute;
    bottom: 6px;
  }
