/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2019, 10:11:58
    Author     : neva
*/

input.custom-text {
    background: white;
    height: 100px;
    line-height: 25px;
    margin-top: 10px;
    box-shadow: 0 1px 1px #aaa;
    margin: 30px 0 0 0;
    border-color: #d3d3d3;
}

button[disabled].button-issue {
    background-color: rgba(0,255,0,0.3)!important;
    border: 1px solid rgba(0,255,0,0.3);
}
.form-stage{
    min-width: unset;
    width: 95%;
    height: 40px;
    color: black;
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    border: 0;
}

.contact-icon, .address-icon {
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
  color: rgb(165, 163, 163);
  transition: 0.3s;
}
.contact-icon:hover, .address-icon:hover {
  color: red;
}

.add-translations {
    position: absolute;
    left: 48%;
    top: 140px;
}

 i.fa.fa-language {
    cursor: pointer;
}

.form-input-field input[type="text"],
.form-input-field input[type="password"],
.form-input-field input[type="email"],
.form-input-field input[type="number"] {
    border: 1px solid #d6d6d6 ;
    height: 40px !important;
    padding: 0 5px !important;
    float: left !important;
    color:  #444 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    border-radius: 3px !important;
    background: transparent !important;
    min-width: 320px !important;
    padding: 10px 0 !important;
    margin-bottom: 15px !important;
    letter-spacing: 0.5px !important;
    width: 100%;
}



.switchComponent {
    width: 85px;
    height: 30px;
    background: #fff;
    margin-left: 0px;
    margin-bottom: 5px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    position: relative;
    border: 1px solid #d6d6d6;
    z-index: 0;
}

.switchComponent input[type=checkbox] {
    visibility: hidden;
}


.switchComponent:after {
    font: 14px/32px Arial,sans-serif;
    position: absolute;
    right: 16px;
    z-index: -1;
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255,255,255,.15);
    text-transform: uppercase;
    content: '✕';
}

.switchComponent:before {
    font: 14px/32px Arial,sans-serif;
    color: #44c699;
    position: absolute;
    left: 16px;
    z-index: -1;
    font-weight: bold;
    text-transform: uppercase;
    content: '✓';
}

.switchComponent label {
    display: block;
    width: 37px;
    height: 24px;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;

    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease;
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 3px;
    z-index: 0;
    background: #dbdadb;
}

.switchComponent input[type=checkbox]:checked + label {
    left: 43px;
    background: #44c699;
}

.button-outline:hover {
    text-decoration: none;
    border-bottom-width: 2px;
}

.grey {
    background-color: #fff;
    color: #3c3c3c;
    border: solid 1px #c4c4c4;
}
.button-outline {
    border: 1px solid;
    border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 100px;
    cursor: pointer;
    background: transparent;
}

.button-submit {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}

.button-submit.disabled {
    background-color: #ccc !important;
    border-color: #ccc !important;
    cursor: not-allowed;
}
