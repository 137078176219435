/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25-Jan-2019, 14:28:13
    Author     : neva
*/
.text-grey {
    color:  #cccbcc!important;
}
.move-right-15 {
    right: 15px;
}
.margin-top-20 {
    margin-top: 20px;
}
td input[disabled] {
    background-color: #ededed!important;
}
.panel-heading {
    text-transform: capitalize;
}
.panel-heading span {
    color: rgb(128, 128, 128);
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'ProximaNovaA',sans-serif;
    cursor: pointer;
}
.add-customer-label:hover{
    text-decoration: underline;
    color: #95d472;
}
 .add-customer-label>p {
    display: inline;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: #95d472;
    line-height: 1;
    cursor: pointer;
}
 .add-customer-label>i {
    font-size: 21px;
    float: left;
    color: gray;
    margin: 0 10px 0 0;
}
.tableCollection .add-customer-label>i{
    font-size: 15px;
    float: left;
    color: gray;
    margin: 1px 4px 0 0;
}
.panel-heading .no-label {
    padding-right: 10px;
}
.panel-heading .row.bottom {
    padding: 32px 20px 33px 14px;
    background: white;
    margin: 20px -30px -15px -30px;
    font-size: 24px;
}
.panel-heading .row.bottom:before {
    content: url('/bundles/system/main/images/sicons/arrow.svg');
    position: absolute;
    margin: -44px 0 0 44%;
}
.panel-heading .row.bottom .total-field {
    font-size: 24px;
}
.panel-heading .row.bottom label {
    font-size: 24px;
}
.billing-data-select .css-va7pk8 {
    font-size: 24px;
}

.meta-data .ant-calendar-picker-input {
    min-width: 100px!important;
}

.panel {
    box-shadow: 0 1px 1px #aaa;
}

.panel.total p.total {
    font-size: 24px;
}

.panel.total {
    min-height: 335px;
}

input:disabled {
    background: #f8f9fc;
}
#reactForm table tr:focus {
    outline: none;
}

.slide-modal .modalConfirms {
    overflow: auto;
}
.slide-modal .modalConfirms input[type="text"], .slide-modal .modalConfirms input[type="password"], .slide-modal .modalConfirms input[type="email"], .slide-modal .modalConfirms input[type="number"], .slide-modal .modalConfirms select.form-control {
    border: 1px solid #d6d6d6;
}
.slide-modal .modalConfirms .css-1aya2g8 {
    border-width: 1px;
}
.total-field, .no-label {
    font-size: 16px;
}
.select2-position{
  position: fixed!important;
  width: 45%;
  z-index: 9999;
}
@media only screen and ( max-width: 767px) {
    .panel-heading {
        padding: 10px;
        letter-spacing: -1px;
    }
    .panel-heading .row.bottom {
        margin: 21px -8px -8px -8px;
        padding: 32px 0 33px 14px;
    }
    .panel-heading .no-label {
        padding-right: 0;
    }
    .slide-modal .modalConfirms {
        width: 100% !important;
        overflow: auto;
    }
    .move-right-15 {
    right: 30px;
}
}
